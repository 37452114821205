// ACCESS COLORS
export let getColor = (colorVar) => {
    let color = getComputedStyle(document.documentElement)
    .getPropertyValue(colorVar);
    return color;
}


export let getWindowNameByNum = (value) => {
        switch (value) {
          case (1):
            return 'January';
          case (2):
            return 'February';
          case (3):
            return 'March';
          case (4):
            return 'April';
          case (5):
            return 'May';
          case (6):
            return 'June';
          case (7):
            return 'July';
          case (8):
            return 'August';
          case (9):
            return 'September';
          case (10):
            return 'October';
          case (11):
            return 'November';
          case (12):
            return 'December';
          case (20):
            return 'Spring (Mar - May)';
          case (30):
            return 'Summer (Jun - Aug)';
          case (40):
            return 'Fall (Sep - Nov)';
          case (50):
            return 'Winter (Dec - Feb)';
          default:
            return new Date().getMonth() + 1;
        }
}

export let getWindowNumByName = (value) => {
    switch (value) {
      case ('January'):
        return 1;
      case ('February'):
        return 2;
      case ('March'):
        return 3;
      case ('April'):
        return 4;
      case ('May'):
        return 5;
      case ('June'):
        return 6;
      case ('July'):
        return 7;
      case ('August'):
        return 8;
      case ('September'):
        return 9;
      case ('October'):
        return 10;
      case ('November'):
        return 11;
      case ('December'):
        return 12;
      case ('Spring (Mar - May)'):
        return 20;
      case ('Summer (Jun - Aug)'):
        return 30;
      case ('Fall (Sep - Nov)'):
        return 40;
      case ('Winter (Dec - Feb)'):
        return 50;
      default:
        return 'Oops';
    }
}