import './NavDrawer.css';
import { Backdrop } from '../Backdrop/Backdrop';


import React from 'react';
import './Nav.css';
import logo from './Assets/logo.svg';
import textLogo from './Assets/textLogo.svg';
import property from './Assets/property.svg';
import propertyActive from './Assets/propertyActive.svg';
import dashboard from './Assets/dashboard.svg';
import dashboardActive from './Assets/dashboardActive.svg';
import transactions from './Assets/transactions.svg';
import transactionsActive from './Assets/transactionsActive.svg';
import calculator from './Assets/calculator.svg';
import calculatorActive from './Assets/calculatorActive.svg';
import taxes from './Assets/taxes.svg';
import taxesActive from './Assets/taxesActive.svg';
import add from './Assets/add.svg';
import user from './Assets/user.svg';
import logout from './Assets/logout.svg';
import { LoadingSmall } from '../../CoreComponents/Loading/LoadingSmall';
import { Logout } from '../../Login/Logout';

import { Link } from 'react-router-dom';
import help from './Assets/help.svg';


export const NavDrawer = (props) => {
    
    if (!props.properties) {
        return <LoadingSmall />;
    }

    else
    {

    let classes = 'sideNavDrawer';
    if (props.status===true) {
        classes += ' sideNavDrawerOpen';
    }

    let drawerType = 'add';
    if (props.properties.length<1){
        drawerType = 'firstProperty'
    }


let timeout;
let handleTimeout = () => {
    if (timeout != null) {
        clearTimeout(timeout);
    } else {
        props.toggleNavDrawer()
    }
}

    return (
        <div onMouseEnter={() => {
            timeout = setTimeout(function(){
                props.toggleNavDrawer()
              }, 500);
            }
            }>
            {props.status ? <Backdrop toggleDrawer={props.toggleNavDrawer} type='nav'/> : ''}
            <div className={classes}>
                <div>


            <div className='container' >
                <ul>
                    <li onClick={() => {
                        handleTimeout();
                        {props.page === 'comingsoon' ? props.pageSetter({response:'comingsoon'}) : props.pageSetter({response:'dashboard'})}
                        
                        }} >

                        <div className='home'>
                            <div className='logoDiv'><img  src={textLogo} /></div>
                            
                            <img src={logo} />
                        </div>
                    </li>


                    <li onClick={() => {
                        handleTimeout();
                        {props.page === 'comingsoon' ? props.pageSetter({response:'comingsoon'}): props.pageSetter({response:'dashboard'})}
                        }}>
                        {
                        props.page === 'dashboard' 
                        ? 
                        <div className='flexy bgColor'>
                            <p className='secondaryLabelGreen textAlign'>Dashboard</p>
                            <img src={dashboardActive} />
                        </div>
                        : 
                        <div className='flexy'>
                            <p className='secondaryLabelBlue textAlign'>Dashboard</p>
                            <img src={dashboard} />
                        </div>
                        }
                    </li>


                    <li onClick={() => {
                        handleTimeout();
                        {props.page === 'comingsoon' ? props.pageSetter({response:'comingsoon'}): props.pageSetter({response:'properties'})}
                        }}>
                        {
                        props.page === 'properties' 
                        ? 
                        <div className='flexy bgColor'>
                            <p className='secondaryLabelGreen textAlign'>My Properties</p>
                            <img src={propertyActive} />
                        </div>
                        : 
                        <div className='flexy'>
                            <p className='secondaryLabelBlue textAlign'>My Properties</p>
                            <img src={property} />
                        </div>
                        }
                    </li>



                    <li onClick={() => {
                        handleTimeout();
                        {props.page === 'comingsoon' ? props.pageSetter({response:'comingsoon'}): props.pageSetter({response:'transactions'})}
                        }}>
                    {
                        props.page === 'transactions' 
                        ? 
                        <div className='flexy bgColor'>
                            <p className='secondaryLabelGreen textAlign'>Transactions</p>
                            <img src={transactionsActive} />
                        </div>
                        :
                        <div className='flexy'>
                            <p className='secondaryLabelBlue textAlign'>Transactions</p>
                            <img src={transactions} />
                        </div>
                    }
                    </li> 
                    


                    <li onClick={() => {
                        handleTimeout();
                        {props.page === 'comingsoon' ? props.pageSetter({response:'comingsoon'}): props.pageSetter({response:'calculator'})}
                        }}>
                    {
                        props.page === 'calculator' 
                        ? 
                        <div className='flexy bgColor'>
                            <p className='secondaryLabelGreen textAlign'>Calculator</p>
                            <img src={calculatorActive} />
                        </div>
                        :
                        <div className='flexy'>
                            <p className='secondaryLabelBlue textAlign'>Calculator</p>
                            <img src={calculator} />
                        </div>
                    }
                    </li>


                    

                    <li onClick={() => {
                        handleTimeout();
                        {props.page === 'comingsoon' ? props.pageSetter({response:'comingsoon'}): props.pageSetter({response:'taxes'})}
                        }}>
                    {
                        props.page === 'taxes' 
                        ? 
                        <div className='flexy bgColor'>
                            <p className='secondaryLabelGreen textAlign'>Tax Hub</p>
                            <img src={taxesActive} />
                        </div>
                        :
                        <div className='flexy'>
                            <p className='secondaryLabelBlue textAlign'>Tax Hub</p>
                            <img src={taxes} />
                        </div>
                    }
                    </li> 

                    <li onClick={() => {
                        handleTimeout();
                        {props.page === 'comingsoon' ? props.pageSetter({response:'comingsoon'}) : props.toggleDrawer({drawerType:drawerType})}
                        
                        }}>
                        <div className='flexy'>
                            <p className='secondaryLabelBlue textAlign'>Add New...</p>
                            <img src={add} />
                        </div>
                    </li>
                </ul>


                <ul className='list2'>
                    <li onClick={() => {
                        handleTimeout();
                        }}>
                               <Link
                                    style={{ textDecoration: 'none' }}
                                    to='#'
                                    onClick={(e) => {
                                        window.location.href = 'mailto:help@wayboz.com';
                                        e.preventDefault();
                                    }}
                                >
                                   <div className='flexy'>
                                        <p className='secondaryLabelBlue textAlign'>Contact Us</p>
                                        <img src={help} />
                                    </div>
                                </Link>
                            
                        
                    </li>
                    
                    
                    <li onClick={() => {
                        handleTimeout();
                        props.toggleDrawer({drawerType:'account'})}}>
                        <div className='flexy'>
                            <p className='secondaryLabelBlue textAlign'>My Account</p>
                            <img src={user} />
                        </div>
                    </li>



                    <li onClick={() => Logout()}>
                        <div className='flexy'>
                            <p className='secondaryLabelBlue textAlign'>Logout</p>
                            <img src={logout} />
                        </div>
                    </li>
                </ul>
            </div>





                </div>
            </div>
        </div>
    )
    }
};