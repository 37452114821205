import React from 'react';
import { FormatNumber } from '../CoreComponents/FormatNumber/FormatNumber';
import { PropertyValueDonutChart } from './PropertyValueDonutChart';
import { getColor } from '../../Utilities/functions';

export const PropertyValue = (props) => {

    let dataObj = props.data[props.month-1];

    return (
        <div className = 'dashboardDataContainer bodyTextBlue'>
            
            <div className = 'dashboardDataContainerHalf1 labelAndNum'>
                <div className='labelCol'>
                    <div className='circleAndLabel'>
                        <p>Property Value:</p>
                    </div>
                    <div className='circleAndLabel borderBottomLine '>
                        <p>Debt:</p>
                    </div>
                    <div className='circleAndLabel '>
                        <p className='secondaryLabelBlue'>Equity:</p>
                    </div>
                    <div className='circleAndLabel'>
                        <p >Loan to Value (LTV):</p>
                    </div>
                </div>
                <div className='numCol'>
                    <p><FormatNumber value={dataObj.value} type='dollarRounded' /></p>
                    <p className='borderBottomLine'><FormatNumber value={dataObj.debt} type='dollarRounded' /></p>
                    <p className='secondaryLabelBlue'><FormatNumber value={dataObj.equity} type='dollarRounded' colorMe='yes' /></p>
                    <p ><FormatNumber value={dataObj.ltv} type='percent' /></p>
                </div>
                
            </div>

            <div className = 'dashboardDataContainerHalf2 valueChart'>
                {dataObj.equity < 0 ? <p><em>Uh oh! You owe more than the property is worth. <span>:&nbsp;(</span></em></p>
                : 
                <PropertyValueDonutChart 
                    dataObj={dataObj}
                     />}
            </div>
            
            
        </div>
        
    )

}