import React, { useState, useEffect } from 'react';
import { TransRow } from './TransactionRow';
import { TransRowMobile } from './TransRowMobile';

export const TransactionsBody = (props) => {
    let showTrans;

    if (props.mobile) {
        showTrans = props.transactions.map((item) => {
            return <TransRowMobile key={`${item.trans_id}`} 
                                item={item} 
                                toggleDrawer={props.toggleDrawer} 
                        
                                />
        })
    } else {
        showTrans = props.transactions.map((item) => {
            return (<div className='transRowHover'>
                        <TransRow key={`${item.trans_id}`} 
                                item={item} 
                                properties={props.properties} 
                                deleteTrans={props.deleteTrans} 
                                transactions={props.transactions} 
                                toggleDrawer={props.toggleDrawer} 
                                refreshSetter={props.refreshSetter}
                                deviceWidth={props.deviceWidth}
                                />
                    </div>)
        })
    }
    

    let pagesArr = [];
    if (props.pages <= 10) {
        for (let i=1; i<props.pages+1; i++){
            pagesArr.push(i);
        }
    } else if (props.pageNum < 5) {
        for (let i=1; i<11; i++){
            pagesArr.push(i);
        }
    } else if (props.pages - props.pageNum < 5) {
        for (let i=props.pages-9; i<props.pages+1; i++){
            pagesArr.push(i);
        }
    } else {
        for (let i=props.pageNum-4; i<props.pageNum+6; i++){
            pagesArr.push(i);
        }
    }
    

    let showPagination = pagesArr.map((item, i) => {
        let styles = 'paginationItem';
        if (item === props.pageNum) {
            styles += ' paginationSelected';
        } 

        return <div key={i} onClick={() => props.handleSetPage({page:item})} className={styles}><p>{item}</p></div>
    })

return (
        
        <div>
            <div style={{opacity:props.opacity}} >{showTrans}</div>
            <div className='paginationContainer secondaryLabelGray'>
                    {props.pageNum === 1 ? <div className='paginationItem' style={{visibility:'hidden'}} onClick={() => props.handleSetPage({page:1})}><p>{`<<`}</p></div> : <div className='paginationItem' onClick={() => props.handleSetPage({page:1})}><p>{`<<`}</p></div>}
                    {showPagination}
                    {props.pageNum === props.pages ? <div className='paginationItem' style={{visibility:'hidden'}} onClick={() => props.handleSetPage({page:props.pages})}><p>{`>>`}</p></div> : <div className='paginationItem' onClick={() => props.handleSetPage({page:props.pages})}><p>{`>>`}</p></div>}
                </div>
        </div>
        
        )


}