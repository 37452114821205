import React, {useState, useEffect} from 'react';
import './InfoContainer.css';
import Edit from './Assets/Edit.svg';
import { getColor } from '../../../Utilities/functions';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import tooltipWhite from '../../../SharedAssets/tooltipWhite.svg';
import { CSVLink, CSVDownload } from "react-csv";
import exportIcon from '../../Taxes/Assets/export.svg';


export const InfoContainer = ({children, ...props}) => {

// -------- Misc Stats Summary Stuff that has to be here b/c I use a useEffect ---------
    const [typeIndex, setTypeIndex] = useState(0);
    const [typeIndexDisplay, setTypeIndexDisplay] = useState(0);
    const [cashFlowClass, setCashFlowClass] = useState()
    const [appreciationClass, setAppreciationClass] = useState()
    const [principleClass, setPrincipleClass] = useState()
    const [tvgClass, setTvgClass] = useState()

    useEffect(() => {
        let basic = 'hover tab tertiaryLabel';
        if (typeIndex === 0) {
            setTypeIndexDisplay('Cash Flow')
            setCashFlowClass(basic + ' cashFlow tabBorderCashFlow')
            setAppreciationClass(basic + ' appreciation')
            setPrincipleClass(basic + ' principle')
            setTvgClass(basic + ' positive')
        } else if (typeIndex === 1) {
            setTypeIndexDisplay('Appreciation')
            setCashFlowClass(basic + ' cashFlow')
            setAppreciationClass(basic + ' appreciation tabBorderAppreciation')
            setPrincipleClass(basic + ' principle')
            setTvgClass(basic + ' positive')
        } else if (typeIndex === 2 ){
            setTypeIndexDisplay('Principle')
            setCashFlowClass(basic + ' cashFlow')
            setAppreciationClass(basic + ' appreciation')
            setPrincipleClass(basic + ' principle tabBorderPrinciple')
            setTvgClass(basic + ' positive')
        } else {
            setTypeIndexDisplay('Total Value Gained')
            setCashFlowClass(basic + ' cashFlow')
            setAppreciationClass(basic + ' appreciation')
            setPrincipleClass(basic + ' principle')
            setTvgClass(basic + ' positive tabBorderTVG')
        }
      }, [typeIndex])



// ----------------- STATS SUMMARY PREP -----------------
let metrics = ['Cash Flow', 'Appreciation', 'Principle', 'Total Value Gained'];

        let cashFlowColor = getColor('--cashFlow');
        let appreciationColor = getColor('--appreciation');
        let principleColor = getColor('--principle');
        let tvgColor = getColor('--positive');
    
        let typeColor = typeIndex === 0 ? cashFlowColor : typeIndex === 1 ? appreciationColor : typeIndex === 2 ? principleColor : tvgColor;
    
        let handleClick = ({type}) => {
            if (type === 'CashFlow'){
                setTypeIndex(0);
            } else if (type === 'Appreciation'){
                setTypeIndex(1);
            } else if (type === 'Principle') {
                setTypeIndex(2);
            } else {
                setTypeIndex(3);
            }
        }
    
        let handleChangeMetric = (e) => {
            let type;
            type = e.target.value;
            if (type === 'Cash Flow'){
                setTypeIndex(0);
            } else if (type === 'Appreciation'){
                setTypeIndex(1);
            } else if (type === 'Principle') {
                setTypeIndex(2);
            } else {
                setTypeIndex(3);
            }
        }







// ----------------- END STATS SUMMARY PREP -----------------


let modifiedProps = React.Children.map(children, child => {
    return React.cloneElement(child, { typeIndex: typeIndex });
  });




    
    return (
        <div className='infoContainer' >
           {props.type === 'transactions'
           ? 
            !props.mobile  
                ? <div className='transactionHeader secondaryLabelWhite' >
                    <p className='transDate '>Date</p>
                    <p className='transMerchant '>Merchant</p>
                    <p className='transAmount '>Amount</p>
                    <p className='transType '>Category</p>
                    <p className='transProperty '>Property</p>
                    {props.deviceWidth && props.deviceWidth <1050 ? '' : <p className='transAccount '>Account</p>}
                    <p className='transHide '>Split</p>
                    <p className='transHide '>Hide</p>
                </div> 
                : <div className='containerHeader secondaryLabelWhite' >
                    <div className='containerLabel'>
                        <p className='labelRightMargin'>{props.label}</p>
                    </div>
                </div>
            
            : 
        
           <div className='containerHeader secondaryLabelWhite' >
                {/* ------------- this is the left side of the label ------------------ */}
                <div className='containerLabel'>
                    <p className='labelRightMargin'>{props.label}</p>
                
                    {props.type === 'ltv' 
                    ? <img className = 'pointer' src={tooltipWhite} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'propertyValueChartTip'})} /> 
                    : props.type === 'statsSummary'
                    ? <img className = 'pointer' src={tooltipWhite} onClick={() => props.toggleDrawer({drawerType:'tooltip', payload:'summary'})} /> 
                    : ''}
                </div>
                


                {/* ------------- this is the right side of the label ------------------ */}
                {props.type === 'taxes'
                ? <CSVLink data={props.prepData()} className='edit' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div className='edit' >
                            <img src={exportIcon} />
                            <p className='secondaryLabelWhite' >Export Data</p>
                        </div>
                    </CSVLink>
                : props.label === 'All Properties' || props.type !== 'stats'
                ? '' 
                :  (<div className='edit' onClick={() => props.toggleDrawer({drawerType:'editProperty', id:props.id})}>
                        <p>Edit Property</p>
                        <img src={Edit} />
                    </div>) }


                {props.mobileType && props.mobileType !== 'large' && props.type === 'statsSummary'  ? 
                
                <TextField
                id="standard-basic"
                variant="standard"
                select
                required
                name='metric'
                value={typeIndexDisplay}
                onChange={handleChangeMetric}
                size='small'
                InputProps={{ disableUnderline: true }}
                sx={{"& .MuiSelect-select": {color: typeColor, fontWeight: 'bold'}, "& .MuiSelect-icon": {color: typeColor}}}
                
                >
                {metrics.map((option) => (
                    <MenuItem key={option} value={option} >
                    {option}
                    </MenuItem>
                ))}
                </TextField>
            

            : props.type === 'statsSummary' ?
            
                <div className='summaryMetrics' >
                    <p className={cashFlowClass} onClick={() => handleClick({type:"CashFlow"})}>Cash Flow</p>
                    <p className={principleClass} onClick={() => handleClick({type:"Principle"})}>Principle Paid</p>
                    <p className={appreciationClass} onClick={() => handleClick({type:"Appreciation"})}>Appreciation</p>
                    <p className={tvgClass} onClick={() => handleClick({type:"TVG"})}>Total Value Gained</p>
                </div>

            : ''
            }

           </div>
        }



            {/* ------------- this is the body content beneath the labels ------------------ */}
           <div className='containerBody' >
               <div style={{opacity:props.opacity}}>{modifiedProps}</div>
           </div>


        </div>
        )
  
};