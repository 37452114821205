import React, { useState, useEffect } from 'react';
import { Loading } from '../CoreComponents/Loading/Loading';
import { getDashboardData } from '../../api/properties';
import { getTaxData } from '../../api/properties';
import { StatsSummary } from '../PropertiesMain/Properties/PropertyStats/StatsSummary/StatsSummary';
import { Transactions } from '../Transactions/Transactions';
import { InfoContainer } from '../CoreStructure/InfoContainer/InfoContainer';
import { PropertyValue } from './PropertyValue';
import './Dashboard.css';
import { CashFlowVsPersonal } from './CashFlowVsPersonal';
import { TaxLiability } from './TaxLiability';
import { ROEVsAssetClasses } from './ROEVsAssetClasses';

export const Dashboard = (props) => {

  const [data, setData] = useState();
  const [taxData, setTaxData] = useState();
  const [showTransactions, setShowTransactions] = useState(true);

  let toggleShowTransactions = ({status}) => {
    setShowTransactions(status);
  }

  let toggleDrawer = props.toggleDrawer

  let loadingSetter = props.loadingSetter
  let properties = props.properties
  let year = props.year
  let month = props.month

  useEffect(() => {
    getTaxData({ properties: properties, year: year }).then(res => setTaxData(res));
  }, [props.taxRefresher])

  useEffect(() => {

    loadingSetter({ response: true })
    getDashboardData({ properties, year, month }).then((res) => {
      setData(res);
      loadingSetter({ response: false })
    });
    // a handful of actions retrigger this entire bit of chaos
  }, [props.propertiesRefresher]);


  if (!data || !taxData) {
    return <Loading />
  }
  else {

    return (
      <div >
        <InfoContainer type='statsSummary' label='Portfolio Summary' toggleDrawer={props.toggleDrawer} mobileType={props.mobileType} properties={props.properties} >
          <StatsSummary
            statsData={data.propertiesData}
            month={props.month}
            currentYear={props.year}
            mobileType={props.mobileType}

          />
        </InfoContainer>

{showTransactions ? 
        <Transactions
          toggleDrawer={toggleDrawer}
          properties={properties}
          property={props.property}
          plaidAccount={props.plaidAccount}
          transactionsRefresher={props.transactionsRefresher}
          refreshSetter={props.refreshSetter}
          mobile={props.mobile}
          deviceWidth={props.deviceWidth}
          accountStatus={props.accountStatus}
          hadTrial={props.hadTrial}
          page={props.page}
          perPage={5}
          toggleShowTransactions = {toggleShowTransactions}
        /> : ''}

        <div className='dashboardRowTwoColumns'>
          <div className='dashboardColHalf'>
            <InfoContainer type='cashflowVsPersonal' label='Cash Flow vs Personal Expenses' toggleDrawer={props.toggleDrawer} mobileType={props.mobileType} properties={props.properties} >
              <CashFlowVsPersonal
                data={data.personalVsProperty}
                month={props.month}
                currentYear={props.year}
                mobileType={props.mobileType}

              />
            </InfoContainer>
            <InfoContainer type='taxLiability' label='Tax Liability' toggleDrawer={props.toggleDrawer} mobileType={props.mobileType} properties={props.properties} >
              <TaxLiability
                data={taxData}
                currentYear={props.year}
              />
            </InfoContainer>

            <InfoContainer type='ltv' label='Property Value' toggleDrawer={props.toggleDrawer} mobileType={props.mobileType} id={props.id} >
              <PropertyValue
                data={data.propertyLTV}
                month={props.month}
                currentYear={props.year}
                mobileType={props.mobileType}

              />

            </InfoContainer>
          </div>

          <div className='dashboardColHalf'>
            <InfoContainer type='roeVsAssetClasses' label='Your Return vs Other Options' toggleDrawer={props.toggleDrawer} mobileType={props.mobileType} properties={props.properties} >
              <ROEVsAssetClasses
                data={data.altInvestments}
                currentYear={props.year}
              />
            </InfoContainer>
          </div>
        </div>
      </div>
    )
  }

};