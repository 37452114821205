import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'; // Import Recharts components for rendering the pie chart

import { getColor } from '../../Utilities/functions';




// Functional component rendering the PieChart with the needle
export const NeedleChart = (props) => {

  let cashFlowColor = getColor('--cashFlow');
  let expenseColor = getColor('--negative')
  let positiveColor = getColor('--positive')
  let waybozBlue = getColor('--waybozBlue')

  let cashflow = props.cashflow;
  let expenses = props.personalExpenses;
  let diff = props.diff;

  let needleValue = expenses;

  if (expenses > cashflow) {
    needleValue = expenses * 1.05;
  }

  const RADIAN = Math.PI / 180; // Constant for converting degrees to radians (used in trigonometric calculations)

  // Sample data for the PieChart, each object has a 'name', 'value', and 'color'
  const data = [
    { name: 'Cash Flow', value: expenses, color: expenseColor }, // Segment A with a value of cashflow value and cashflow color
  ];
  
  if (diff >= 0) {
    data.push({ name: 'Leftover Cash Flow', value: diff, color: positiveColor }); // Only add the second segment if diff >= 0
  }

  // Chart configuration values
  const cx = 90; // X-coordinate of the chart's center
  const cy = 90; // Y-coordinate of the chart's center
  const iR = 50;  // Inner radius of the pie chart (creates a donut-like appearance)
  const oR = 90; // Outer radius of the pie chart (determines the size of the chart)
  const value = needleValue; // Value to be displayed by the needle (used to position it within the chart)

  // Function to draw the needle based on the provided value and data
  const needle = (value, data, cx, cy, iR, oR, color) => {
    let total = 0;

    // Sum up the total values of all data points
    data.forEach((v) => {
      total += v.value;
    });

    // Calculate the angle of the needle based on the value relative to the total
    const ang = 180.0 * (1 - value / total);

    // Calculate the length of the needle to ensure it fits within the chart
    const length = (iR + 2 * oR) / 3;

    // Calculate the sine and cosine of the angle (used for positioning)
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);

    // Define the radius of the circle at the base of the needle
    const r = 7.5;

    // Calculate the base position of the needle (x0, y0) relative to the chart's center
    const x0 = cx + 5;
    const y0 = cy + 5;

    // Calculate the left and right base points of the needle triangle
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;

    // Calculate the needle tip position
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    // Return the circle and triangle path that form the needle, using the calculated coordinates
    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />, // Circle at the base of the needle
      <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />, // Triangle representing the needle
    ];
  };



  return (
        <PieChart width={190} height={120}> {/* PieChart component without specified width and height */}
          <Pie
            dataKey="value" // Use the 'value' key from the data array for the PieChart
            startAngle={180} // Start the chart at 180 degrees (half-circle)
            endAngle={0}     // End the chart at 0 degrees (creating a half-pie)
            data={data}      // The data array to be displayed in the pie chart
            cx={cx}          // X-coordinate of the chart center
            cy={cy}          // Y-coordinate of the chart center
            innerRadius={iR} // Inner radius of the pie (creates a donut-like hole in the middle)
            outerRadius={oR} // Outer radius (size of the pie)
            fill={cashFlowColor}   // Default fill color for the pie segments
            stroke="none"    // No border around the pie segments
          >
            {/* Map through the data and render each Cell with its respective color */}
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {/* Render the needle component on top of the pie chart */}
          {needle(value, data, cx, cy, iR, oR, waybozBlue)}
        </PieChart>
  );
};
