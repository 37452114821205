import React from 'react';
import { FormatNumber } from '../../../../CoreComponents/FormatNumber/FormatNumber';
import './StatsSummary.css';

import {useState, useEffect} from 'react';

import {getWindowNameByNum} from '../../../../../Utilities/functions'


export const StatsSummary = (props) => {

    let statsData = props.statsData;
    let typeIndex = props.typeIndex;
    let displayMonth = getWindowNameByNum(props.month);
   // let propertyID = props.id;

    let allPropsStyle = 'flexAndMarginRight bodyTextBlue'
    /*if (propertyID === -1) {
        allPropsStyle = 'flexAndMarginRight bodyTextBlue bold'
    }*/



    let propertyRows = statsData.slice(1).map((item, index) => {

        let style = 'flexAndMarginRight bodyTextBlue'
        if (item.propertyID === -1) {
        style = 'flexAndMarginRight bodyTextBlue bold';
        }

        return (
            <div className={style} key={index}>
                <div className='colWidth'><p id='alignLeft'>{item.streetAddress}</p></div>
                <div className='colWidth3 '><p><FormatNumber value={item.allTimeData[typeIndex].M2D} type='dollarRounded' /></p></div>
                <div className='colWidth4 '><p><FormatNumber value={item.allTimeData[typeIndex].M2DROE} type='percent' /></p></div>
                <div className='colWidth3 '><p><FormatNumber value={item.allTimeData[typeIndex].Y2D} type='dollarRounded' /></p></div>
                <div className='colWidth4 '><p><FormatNumber value={item.allTimeData[typeIndex].Y2DROE} type='percent' /></p></div>
                <div className='colWidth3 '><p><FormatNumber value={item.allTimeData[typeIndex].EOY} type='dollarRounded' /></p></div>
                <div className='colWidth4 '><p><FormatNumber value={item.allTimeData[typeIndex].EOYROE} type='percent' /></p></div>
                <div className='colWidth3 '><p><FormatNumber value={item.allTimeData[typeIndex].AllTime} type='dollarRounded' /></p></div>
                <div className='colWidth4 '><p>{item.allTimeData[typeIndex].AllTimeROE === 'Infinite' ? 'Infinite' : <FormatNumber value={item.allTimeData[typeIndex].AllTimeROE} type='percent' />}</p></div>
            </div>
    )});

    
// ----------- mobile stuff -----------

const [timePeriod, setTimePeriod] = useState('current_month');
const [timePeriodDisplay, setTimePeriodDisplay] = useState();
const [earning, setEarning] = useState();
const [roe, setRoe] = useState();


useEffect( () => {
    if (timePeriod === 'current_month'){
        setTimePeriodDisplay(`${displayMonth} ${props.currentYear}`)
        setEarning('M2D')
        setRoe('M2DROE')
    } else if (timePeriod === 'current_year') {
        setTimePeriodDisplay(`${props.currentYear} Total`)
        setEarning('Y2D')
        setRoe('Y2DROE')
    } else if (timePeriod === 'predicted_year') {
        setTimePeriodDisplay('Predicted EoY')
        setEarning('EOY')
        setRoe('EOYROE')
    } else {
        setTimePeriodDisplay('All Time')
        setEarning('AllTime')
        setRoe('AllTimeROE')
    }
  }, [timePeriod]);


let handleClick = ({type}) => {
    if (type === 'right'){
        if (timePeriod === 'current_month'){
            setTimePeriod('current_year')
        } else if (timePeriod === 'current_year') {
            setTimePeriod('predicted_year')
        } else if (timePeriod === 'predicted_year') {
            setTimePeriod('all_time')
        } else {
            setTimePeriod('current_month')
        }
    } else {
        if (timePeriod === 'current_month'){
            setTimePeriod('all_time')
        } else if (timePeriod === 'current_year') {
            setTimePeriod('current_month')
        } else if (timePeriod === 'predicted_year') {
            setTimePeriod('current_year')
        } else {
            setTimePeriod('predicted_year')
        }
    }
}

let mobilePropertyRows = statsData.slice(1).map((item, index) => {

    return (
            <div className='summaryRowMobile bodyTextBlue' key={index}>
                <div>
                    <p>{item.streetAddress}</p>
                </div>
                <div className='summaryMetricsMobile'>
                    <p className='mobileMetricMargin'><FormatNumber value={item.allTimeData[typeIndex][earning]} type='dollarRounded' /></p>
                    <p className='tertiaryLabelLight'>{item.allTimeData[typeIndex][roe] === 'Infinite' ? <em>ROE: Infinite</em> : <em>ROE: <FormatNumber value={item.allTimeData[typeIndex][roe]} type='percent' /></em>}</p>
                </div>
            </div>
)});


// ---------- end mobile stuff ----------


return (
    <div>
        {props.mobileType && props.mobileType !== 'large' ? 
        <div className='summaryContainer'>
            <div className='flexAndMarginMobile secondaryLabelBlueSemi'>
                <p className='carrots' onClick={() => handleClick({type:'left'})}>{`<`}</p>
                <div className='setWidth'><p>{timePeriodDisplay}</p></div>
                <p className='carrots' onClick={() => handleClick({type:'right'})} >{`>`}</p>
            </div>

            <div className='summaryRowMobile bodyTextBlue'>
                <div>
                    <p>All Properties</p>
                </div>
                <div className='summaryMetricsMobile'>
                    <p className='mobileMetricMargin'><FormatNumber value={statsData[0].allTimeData[typeIndex][earning]} type='dollarRounded' /></p>
                    {statsData[0].allTimeData[typeIndex][roe] === 'Infinite' ? <p className='tertiaryLabelLight'><em>ROE: Infinite</em></p> : <p className='tertiaryLabelLight'><em>ROE: <FormatNumber value={statsData[0].allTimeData[typeIndex][roe]} type='percent' /></em></p>}
                </div>
            </div>
            <hr className='statsLine' />
            {mobilePropertyRows}

        </div>

        : 

        <div className='summaryContainer'>
            <div className=' bodyTextBlue bgSummaryColor scroll'>
                <div className='minWidth'>
                <div className='flexAndMarginRight secondaryLabelBlueSemi'>
                    <div className='colWidth '></div>
                    <div className='colWidth '><p>{`${displayMonth} ${props.currentYear}`}</p></div>
                    <div className='colWidth '><p>{`${props.currentYear} Total`}</p></div>
                    <div className='colWidth '><p>Predicted EoY</p></div>
                    <div className='colWidth '><p>All Time</p></div>
                </div>
                <div className='flexAndMarginRight tertiaryLabelLight'>
                    <div className='colWidth '></div>
                    <div className='colWidth3 '><p><em>Earnings</em></p></div>
                    <div className='colWidth4 '><p><em>Return</em></p></div>
                    <div className='colWidth3 '><p><em>Earnings</em></p></div>
                    <div className='colWidth4 '><p><em>Return</em></p></div>
                    <div className='colWidth3 '><p><em>Earnings</em></p></div>
                    <div className='colWidth4 '><p><em>Return</em></p></div>
                    <div className='colWidth3 '><p><em>Earnings</em></p></div>
                    <div className='colWidth4 '><p><em>Return</em></p></div>
                </div>
                <div className={allPropsStyle}>
                    <div className='colWidth '><p id='alignLeft'>All Properties</p></div>
                    <div className='colWidth3 '><p><FormatNumber value={statsData[0].allTimeData[typeIndex].M2D} type='dollarRounded' /></p></div>
                    <div className='colWidth4 '><p><FormatNumber value={statsData[0].allTimeData[typeIndex].M2DROE} type='percent' /></p></div>
                    <div className='colWidth3 '><p><FormatNumber value={statsData[0].allTimeData[typeIndex].Y2D} type='dollarRounded' /></p></div>
                    <div className='colWidth4 '><p><FormatNumber value={statsData[0].allTimeData[typeIndex].Y2DROE} type='percent' /></p></div>
                    <div className='colWidth3 '><p><FormatNumber value={statsData[0].allTimeData[typeIndex].EOY} type='dollarRounded' /></p></div>
                    <div className='colWidth4 '><p><FormatNumber value={statsData[0].allTimeData[typeIndex].EOYROE} type='percent' /></p></div>
                    <div className='colWidth3 '><p><FormatNumber value={statsData[0].allTimeData[typeIndex].AllTime} type='dollarRounded' /></p></div>
                    <div className='colWidth4 '><p>{statsData[0].allTimeData[typeIndex].AllTimeROE === 'Infinite' ? 'Infinite' : <FormatNumber value={statsData[0].allTimeData[typeIndex].AllTimeROE} type='percent' />}</p></div>
                
                </div>
                <hr className='statsLine' />
                {propertyRows}
                </div>
            </div>

        </div>}
    </div>



)

}