import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Text } from 'recharts'; 
import { FormatNumber } from '../CoreComponents/FormatNumber/FormatNumber';
import { getColor } from '../../Utilities/functions';




export const PropertyValueDonutChart = (props) => {

let equityColor = getColor('--positive');
let debt = getColor('--midRed');
let textColor = getColor('--waybozBlue')
    
let dataObj = props.dataObj


const keysToInclude = ["debt", "equity"];
const colorMapping = {
    debt: debt,
    equity: equityColor,
  };

  const data = Object.entries(dataObj)
  .filter(([key]) => keysToInclude.includes(key))
  .reverse() // Reversing the order to make equity come first
  .map(([name, value]) => ({
    name,
    value,
    color: colorMapping[name], // Assign color based on the key
  }));


let value = `${new Intl.NumberFormat('en-US', { style:'decimal',  minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(100 - (dataObj.ltv || 0))}%`;

const outerRadius = 70;
const innerRadius = 50;

return (
  <ResponsiveContainer width="100%" height={145}>
    <PieChart>
      <Pie
        data={data}
        dataKey="value"
        outerRadius={outerRadius}
        innerRadius={innerRadius}
        startAngle={180}
        endAngle={-180}
        labelLine={false}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      {/* Custom Text in the center */}
      <svg>
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            fill={textColor}
          >
            <tspan x="50%" dy="-1.2em" fontSize="12" fontWeight="400" >You own</tspan>
            <tspan x="51%" dy="1.6em"  fontSize="18" fontWeight="700" >{value}</tspan>
          </text>
        </svg>
    </PieChart>
  </ResponsiveContainer>
);
};
