import React from 'react';
import './PropertyStats.css';
import { useState, useEffect } from 'react';
import {Loading} from '../../../CoreComponents/Loading/Loading';

import { TotalVG } from './TotalVG/TotalVG';
import { CashFlow } from './CashFlow/CashFlow';
import { ProjectedROE } from './ProjectedROE/ProjectedROE';
import { MonthlyVG } from './MonthlyVG/MonthlyVG';
import { ROEComps } from './ROEComps/ROEComps';
import { NextSteps } from './NextSteps/NextSteps';
import { WindowCashFlow } from './WindowCashFlow/WindowCashFlow';
import { YearCashFlow } from './YearCashFlow/YearCashFlow';
import { PersonalExpenses } from './PersonalExpenses/PersonalExpenses';
import { ROE } from './ROE/ROE';
import { AllTime } from './AllTime/AllTime';

export const PropertyStats = (props) => {
    let year = props.year;
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1;
    let selectedPropertyID=props.id;
    let data=props.data;
    const [propertyData, setPropertyData] = useState();
      
      useEffect( () => {
            let index2 = data.propertyDataArr.findIndex((item) => item.propertyID === selectedPropertyID);
            setPropertyData(data.propertyDataArr[index2]);
      }, [selectedPropertyID, data]);

// if i dont like the loading experience on changing year or window, just remove "|| props.loading"
if (!propertyData /*|| props.loading*/) {
    return <Loading />
}
else {
    
    return (
        <div className="statsContainer">
            
            {props.loading ? 
                <div className='calculatingData tertiaryLabelLightColorless positive'>
                    <p><em>Recrunching your data...</em></p> 
                    <div className="spinner2"></div>
                </div>: 
                ''}

            <WindowCashFlow 
                window={props.windowName}
                timeInWindow={data.timeInWindow}
                year={year}
                currentYear={currentYear}
                currentMonth={currentMonth}
                windowIncomeForYear={propertyData.windowIncomeForYear}
                windowExpenseForYear={propertyData.windowExpenseForYear}
                windowCashFlowForYear={propertyData.windowCashFlowForYear}
                averageWindowIncome={propertyData.averageWindowIncome}
                averageWindowExpense={propertyData.averageWindowExpense}
                averageWindowCashFlow={propertyData.averageWindowCashFlow}
                windowIncomeLast10={propertyData.windowIncomeLast10}
                windowExpenseLast10={propertyData.windowExpenseLast10}
                windowCashFlowLast10={propertyData.windowCashFlowLast10}
                
                propertyID={propertyData.propertyID}
                toggleDrawer={props.toggleDrawer}
                mobileType={props.mobileType}

            />
           
           <YearCashFlow 
                year={year}
                currentYear={currentYear}
                annualIncomeForYear={propertyData.annualIncomeForYear}
                annualExpenseForYear={propertyData.annualExpenseForYear}
                annualCashFlowForYear={propertyData.annualCashFlowForYear}
                averageAnnualIncome={propertyData.averageAnnualIncome}
                averageAnnualExpense={propertyData.averageAnnualExpense}
                averageAnnualCashFlow={propertyData.averageAnnualCashFlow}
                annualIncomeLast10={propertyData.annualIncomeLast10}
                annualExpenseLast10={propertyData.annualExpenseLast10}
                annualCashFlowLast10={propertyData.annualCashFlowLast10}

                averageMonthlyIncome={propertyData.averageMonthlyIncome}
                averageMonthlyExpense={propertyData.averageMonthlyExpense}
                averageMonthlyCashFlow={propertyData.averageMonthlyCashFlow}
                averageMonthlyIncomeByMonth={propertyData.averageMonthlyIncomeByMonth}
                averageMonthlyExpenseByMonth={propertyData.averageMonthlyExpenseByMonth}
                averageMonthlyCashFlowByMonth={propertyData.averageMonthlyCashFlowByMonth}
                monthlyIncomeForYear={propertyData.monthlyIncomeForYear}
                monthlyExpenseForYear={propertyData.monthlyExpenseForYear}
                monthlyCashFlowForYear={propertyData.monthlyCashFlowForYear}

                propertyID={propertyData.propertyID}
                toggleDrawer={props.toggleDrawer}
                mobileType={props.mobileType}

            />




            <PersonalExpenses 
                year={year}
                currentYear={currentYear}

                annualPersonalExpenseForYear={data.annualPersonalExpenseForYear}
                annualCashFlowForYear={propertyData.annualCashFlowForYear}
                
                averageMonthlyPersonalExpenseForYear={data.averageMonthlyPersonalExpenseForYear}
                monthlyPersonalExpenseForYear={data.monthlyPersonalExpenseForYear}
                averageMonthlyCashFlowForYear={propertyData.averageMonthlyCashFlowForYear}
                monthlyCashFlowForYear={propertyData.monthlyCashFlowForYear}

                annualPersonalExpenseLast10={data.annualPersonalExpenseLast10}
                averageAnnualPersonalExpense={data.averageAnnualPersonalExpense}
                annualCashFlowLast10={propertyData.annualCashFlowLast10}
                averageAnnualCashFlow={propertyData.averageAnnualCashFlow}

                propertyID={propertyData.propertyID}
                toggleDrawer={props.toggleDrawer}
                mobileType={props.mobileType}
            />

            
            

            <TotalVG 
                year={year}
                currentYear={currentYear}

                annualTVGForYear={propertyData.annualTVGForYear}
                annualAppreciationForYear={propertyData.annualAppreciationForYear}
                annualPrincipleForYear={propertyData.annualPrincipleForYear}
                annualCashFlowForYear={propertyData.annualCashFlowForYear}

                monthlyRunningTotalCashFlowForYear={propertyData.monthlyRunningTotalCashFlowForYear}
                monthlyRunningTotalAppreciationForYear={propertyData.monthlyRunningTotalAppreciationForYear}
                monthlyRunningTotalPrincipleForYear={propertyData.monthlyRunningTotalPrincipleForYear}
                monthlyRunningTotalTVGForYear={propertyData.monthlyRunningTotalTVGForYear}

                assetReturn={data.assetReturn}
                eoyCashFlowROELast10={propertyData.eoyCashFlowROELast10}
                eoyPrincipleROELast10={propertyData.eoyPrincipleROELast10}
                eoyAppreciationROELast10={propertyData.eoyAppreciationROELast10}
                eoyTVGROELast10={propertyData.eoyTVGROELast10}


                propertyID={propertyData.propertyID}
                toggleDrawer={props.toggleDrawer}
                mobileType={props.mobileType}
                />
            



            <ROE
                year={year}
                currentYear={currentYear}

                eoyCashFlowROE={propertyData.eoyCashFlowROE}
                eoyAppreciationROE={propertyData.eoyAppreciationROE}
                eoyPrincipleROE={propertyData.eoyPrincipleROE}
                eoyTVGROE={propertyData.eoyTVGROE}
                yearStartingEquity={propertyData.yearStartingEquity}

                eoyCashFlow={propertyData.eoyCashFlow}
                eoyAppreciation={propertyData.eoyAppreciation}
                eoyPrinciple={propertyData.eoyPrinciple}
                eoyTVG={propertyData.eoyTVG}

                assetReturns={data.assetReturns}
                eoyCashFlowROELast10={propertyData.eoyCashFlowROELast10}
                eoyPrincipleROELast10={propertyData.eoyPrincipleROELast10}
                eoyAppreciationROELast10={propertyData.eoyAppreciationROELast10}
                eoyTVGROELast10={propertyData.eoyTVGROELast10}

                propertyID={propertyData.propertyID}
                toggleDrawer={props.toggleDrawer}
                mobileType={props.mobileType}                
            />


            <AllTime 
                year={year}
                currentYear={currentYear}
                allTimeTVG={propertyData.allTimeTVG}
                downpayment={propertyData.downpayment}

                yearlyRunningTotalCashFlowForAllTime={propertyData.yearlyRunningTotalCashFlowForAllTime}
                yearlyRunningTotalAppreciationForAllTime={propertyData.yearlyRunningTotalAppreciationForAllTime}
                yearlyRunningTotalPrincipleForAllTime={propertyData.yearlyRunningTotalPrincipleForAllTime}
                yearlyRunningTotalTVGForAllTime={propertyData.yearlyRunningTotalTVGForAllTime}

                monthlyLTVForYear={propertyData.monthlyLTVForYear}

                propertyID={propertyData.propertyID}
                toggleDrawer={props.toggleDrawer}
                mobileType={props.mobileType}
            />







            
     

            <NextSteps />

            
        </div>

        
    )
}
};


