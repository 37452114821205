import React, {useState, useEffect} from 'react';
import './MobileNavDrawer.css'
import calculator from '../../Nav/Assets/calculator.svg';
import dashboard from '../../Nav/Assets/dashboard.svg';
import property from '../../Nav/Assets/property.svg';
import transactions from '../../Nav/Assets/transactions.svg';
import taxes from '../../Nav/Assets/taxes.svg';
import add from '../../Nav/Assets/add.svg';
import help from '../../Nav/Assets/help.svg';
import user from '../../Nav/Assets/user.svg';

import { Link } from 'react-router-dom';






export const MobileNavDrawer = (props) => {
  
    let drawerType = 'add';
    if (props.properties.length<1){
        drawerType = 'firstProperty'
    }

    return (
        <div >
            <p className='primaryLabel primaryTitle'>Where to next?</p> 
                <div className='content introMargin tertiaryLabelLight'>
                    <p>Choose an option to continue:</p>
                </div>

                <div className='buttonFlexNav'>
                    <div className='drawerButtonNav  headerMetric drawerButtonWidth' onClick={() => {
                            props.toggleDrawer({drawerType:''})
                            props.pageSetter({response:'dashboard'})}}>
                        <img src={dashboard} /> 
                        <p>Dashboard</p>
                    </div>
                    <div className='drawerButtonNav  headerMetric drawerButtonWidth' onClick={() => {
                            props.toggleDrawer({drawerType:''})
                            props.pageSetter({response:'properties'})}}>
                        <img src={property} /> 
                        <p>My Properties</p>
                    </div>
                    <div className='drawerButtonNav  headerMetric drawerButtonWidth' onClick={() => {
                            props.toggleDrawer({drawerType:''})
                            props.pageSetter({response:'transactions'})}}>
                        <img src={transactions} /> 
                        <p>Transactions</p>
                    </div>
                    <div className='drawerButtonNav  headerMetric drawerButtonWidth' onClick={() => {
                            props.toggleDrawer({drawerType:''})
                            props.pageSetter({response:'calculator'})}}>
                        <img src={calculator} /> 
                        <p>Calculator</p>
                    </div>
                    <div className='drawerButtonNav  headerMetric drawerButtonWidth' onClick={() => {
                            props.toggleDrawer({drawerType:''})
                            props.pageSetter({response:'taxes'})}}>
                        <img src={taxes} /> 
                        <p>Tax Hub</p>
                    </div>
                    <div className='drawerButtonNav  headerMetric drawerButtonWidth' onClick={() => props.toggleDrawer({drawerType:drawerType})}>
                        <img src={add} /> 
                        <p>Add New...</p>
                    </div>
                    <div className='drawerButtonNav  headerMetric drawerButtonWidth' onClick={() => {
                                window.location.href = 'mailto:help@wayboz.com';
                               }}>    
                        <img src={help} /> 
                        <p>Contact Us</p>
                    </div>
                    <div className='drawerButtonNav  headerMetric drawerButtonWidth' onClick={() => props.toggleDrawer({drawerType:'account'})}>
                        <img src={user} /> 
                        <p>My Account</p>
                    </div>
                    
                </div>
                
        </div>
    )
};