import React, {useState, useEffect} from 'react';
import { PropertyMenu } from '../PropertiesMain/Properties/PropertyMenu/PropertyMenu';
import { TaxesBody } from './TaxesBody';
import { getTaxData } from '../../api/properties';
import { Loading } from '../CoreComponents/Loading/Loading';
import { CSVLink, CSVDownload } from "react-csv";
import exportIcon from './Assets/export.svg';
import { altFormatNumber, textfieldColorChanges } from '../CoreStructure/Drawer/Shared/Misc'
import { InfoContainer } from '../CoreStructure/InfoContainer/InfoContainer';

import './TaxesMain.css';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';



export const TaxesMain = (props) => {
    let properties = props.propertiesNoPics;

    let year = props.year;
    let yearsArr=props.yearsArr
    let handleChangeYear=props.handleChangeYear
    let menuSize = props.mobile ? 'small' : 'normal'
    let handleChangeYearLocal = (e) => {
        handleChangeYear({e, type:'taxes'})
    }

    let taxRefresher=props.taxRefresher;
    
    const [data, setData] = useState(0);

    useEffect(() => {
        getTaxData({properties:properties, year:year}).then(res => setData(res));
      }, [year, taxRefresher])

    const [property, setProperty] = useState({id:-1});
    
    
    
    let updateProperty = ({property}) => {
        setProperty(property);
    }

let prepData = () => {
    const csvData = [["Amount", "Income or Expense?", "Category", "Subcategory",  "Taxation Type", "Street","City","State", "Zip"]]

    
    for (let i=0; i<data.length; i++) {
        let street = 'N/A - All', city = 'N/A - All', state = 'N/A - All', zip = 'N/A - All';
        if (data[i].propertyID !== -1){
            let index = properties.findIndex((e) => e.id === data[i].propertyID);
            street = properties[index].street;
            city = properties[index].city;
            state = properties[index].state;
            zip = properties[index].zip;
        }
        
        //--------- Incomes --------
        let totalIncome = [altFormatNumber({value:data[i].income.totalInc, type:'dollar'}), "Income", "Total Income", "N/A", "Partially Taxable", street, city, state, zip];
        let totalTaxableIncome = [altFormatNumber({value:data[i].income.taxable, type:'dollar'}), "Income", "Total Taxable Income", "N/A", "Taxable", street, city, state, zip];
        let totalNontaxableIncome = [altFormatNumber({value:data[i].income.nontaxable, type:'dollar'}), "Income", "Total Non Taxable Income", "N/A",  "Non Taxable", street, city, state, zip];
        let deposits = [altFormatNumber({value:data[i].income.deposits, type:'dollar'}), "Income", "Deposits", "N/A",  "Non Taxable", street, city, state, zip];
        let misc = [altFormatNumber({value:data[i].income.miscInc, type:'dollar'}), "Income", "Misc", "N/A", "Taxable", street, city, state, zip];
        let paycheck = [altFormatNumber({value:data[i].income.paycheck, type:'dollar'}), "Income", "Paycheck", "N/A", "Taxable", street, city, state, zip];
        let rent = [altFormatNumber({value:data[i].income.rent, type:'dollar'}), "Income", "Rent", "N/A", "Taxable", street, city, state, zip];

        // ---------- Expenses ----------
        let totalExpenses = [altFormatNumber({value:data[i].expense.totalExp, type:'dollar'}), "Expense", "Total Expenses", "N/A", "Partially Depreciable", street, city, state, zip];
        let totalDepreciableExpenses = [altFormatNumber({value:data[i].expense.depreciable, type:'dollar'}), "Expense", "Depreciable Expenses", "N/A", "Depreciable", street, city, state, zip];
        let totalNondepreciable = [altFormatNumber({value:data[i].expense.nondepreciable, type:'dollar'}), "Expense", "Non Depreciable Expenses", "N/A", "Non Depreciable", street, city, state, zip];
        let majorRepairs = [altFormatNumber({value:data[i].expense.majorRepairs, type:'dollar'}), "Expense", "Major Repairs", "N/A", "Depreciable", street, city, state, zip];
        let miscExp = [altFormatNumber({value:data[i].expense.miscExp, type:'dollar'}), "Expense", "Misc Expenses", "N/A", "Non Depreciable", street, city, state, zip];
        let propertyTaxes = [altFormatNumber({value:data[i].expense.propertyTaxes, type:'dollar'}), "Expense", "Property Taxes", "N/A", "Non Depreciable", street, city, state, zip];
        let totalBasicRepairs = [altFormatNumber({value:data[i].expense.basicRepairs.total, type:'dollar'}), "Expense", "Basic Repairs", "Total", "Non Depreciable", street, city, state, zip];
        let basicRepairs = [altFormatNumber({value:data[i].expense.basicRepairs.basic, type:'dollar'}), "Expense", "Basic Repairs", "Repairs", "Non Depreciable", street, city, state, zip];
        let appliancesAndFurnishingsRepairs = [altFormatNumber({value:data[i].expense.basicRepairs.appliancesAndFurnishings, type:'dollar'}), "Expense", "Basic Repairs", "Appliances & Furnishings", "Non Depreciable", street, city, state, zip];
        let totalBusiness = [altFormatNumber({value:data[i].expense.business.total, type:'dollar'}), "Expense", "Business", "Total", "Non Depreciable", street, city, state, zip];
        let miscBusiness = [altFormatNumber({value:data[i].expense.business.miscBusiness, type:'dollar'}), "Expense", "Business", "Misc", "Non Depreciable", street, city, state, zip];
        let phone = [altFormatNumber({value:data[i].expense.business.phone, type:'dollar'}), "Expense", "Business", "Phone", "Non Depreciable", street, city, state, zip];
        let software = [altFormatNumber({value:data[i].expense.business.software, type:'dollar'}), "Expense", "Business", "Software", "Non Depreciable", street, city, state, zip];
        let travel = [altFormatNumber({value:data[i].expense.business.travel, type:'dollar'}), "Expense", "Business", "Travel", "Non Depreciable", street, city, state, zip];
        let totalInsurance = [altFormatNumber({value:data[i].expense.insurance.total, type:'dollar'}), "Expense", "Insurance", "Total", "Non Depreciable", street, city, state, zip];
        let homeowners = [altFormatNumber({value:data[i].expense.insurance.homeowners, type:'dollar'}), "Expense", "Insurance", "Homeowners", "Non Depreciable", street, city, state, zip];
        let pmi = [altFormatNumber({value:data[i].expense.insurance.pmi, type:'dollar'}), "Expense", "Insurance", "PMI", "Non Depreciable", street, city, state, zip];
        let umbrella = [altFormatNumber({value:data[i].expense.insurance.umbrella, type:'dollar'}), "Expense", "Insurance", "Umbrella", "Non Depreciable", street, city, state, zip];
        let totalInterest = [altFormatNumber({value:data[i].expense.interest.total, type:'dollar'}), "Expense", "Interest", "Total", "Non Depreciable", street, city, state, zip];
        let totalLegalFees = [altFormatNumber({value:data[i].expense.legalFees.total, type:'dollar'}), "Expense", "Legal Fees", "Total", "Non Depreciable", street, city, state, zip];
        let legal = [altFormatNumber({value:data[i].expense.legalFees.legal, type:'dollar'}), "Expense", "Legal Fees", "Legal", "Non Depreciable", street, city, state, zip];
        let tax = [altFormatNumber({value:data[i].expense.legalFees.tax, type:'dollar'}), "Expense", "Legal Fees", "Tax", "Non Depreciable", street, city, state, zip];
        let totalMaintenance = [altFormatNumber({value:data[i].expense.maintenance.total, type:'dollar'}), "Expense", "Maintenance", "Total", "Non Depreciable", street, city, state, zip];
        let cleaning = [altFormatNumber({value:data[i].expense.maintenance.cleaning, type:'dollar'}), "Expense", "Maintenance", "Cleaning", "Non Depreciable", street, city, state, zip];
        let pestControl = [altFormatNumber({value:data[i].expense.maintenance.pestControl, type:'dollar'}), "Expense", "Maintenance", "Pest Control", "Non Depreciable", street, city, state, zip];
        let servicesMisc = [altFormatNumber({value:data[i].expense.maintenance.servicesMisc, type:'dollar'}), "Expense", "Maintenance", "Misc", "Non Depreciable", street, city, state, zip];
        let yard = [altFormatNumber({value:data[i].expense.maintenance.yard, type:'dollar'}), "Expense", "Maintenance", "Yard", "Non Depreciable", street, city, state, zip];
        let totalManagement = [altFormatNumber({value:data[i].expense.management.total, type:'dollar'}), "Expense", "Management", "Total", "Non Depreciable", street, city, state, zip];
        let hoa = [altFormatNumber({value:data[i].expense.management.hoa, type:'dollar'}), "Expense", "Management", "HOA", "Non Depreciable", street, city, state, zip];
        let propertyManagement = [altFormatNumber({value:data[i].expense.management.propertyManagement, type:'dollar'}), "Expense", "Management", "Property Management", "Non Depreciable", street, city, state, zip];
        let security = [altFormatNumber({value:data[i].expense.management.security, type:'dollar'}), "Expense", "Management", "Security", "Non Depreciable", street, city, state, zip];
        let totalPurchases = [altFormatNumber({value:data[i].expense.purchases.total, type:'dollar'}), "Expense", "Purchases", "Total", "Non Depreciable", street, city, state, zip];
        let appliances = [altFormatNumber({value:data[i].expense.purchases.appliances, type:'dollar'}), "Expense", "Purchases", "Appliances", "Non Depreciable", street, city, state, zip];
        let furnishings = [altFormatNumber({value:data[i].expense.purchases.furnishings, type:'dollar'}), "Expense", "Purchases", "Furnishings", "Non Depreciable", street, city, state, zip];
        let miscSupplies = [altFormatNumber({value:data[i].expense.purchases.miscSupplies, type:'dollar'}), "Expense", "Purchases", "Misc Supplies", "Non Depreciable", street, city, state, zip];
        let totalUtilities = [altFormatNumber({value:data[i].expense.utilities.total, type:'dollar'}), "Expense", "Utilities", "Total", "Non Depreciable", street, city, state, zip];
        let electric = [altFormatNumber({value:data[i].expense.utilities.electric, type:'dollar'}), "Expense", "Utilities", "Electric", "Non Depreciable", street, city, state, zip];
        let gas = [altFormatNumber({value:data[i].expense.utilities.gas, type:'dollar'}), "Expense", "Utilities", "Gas", "Non Depreciable", street, city, state, zip];
        let internet = [altFormatNumber({value:data[i].expense.utilities.internet, type:'dollar'}), "Expense", "Utilities", "Internet", "Non Depreciable", street, city, state, zip];
        let sewer = [altFormatNumber({value:data[i].expense.utilities.sewer, type:'dollar'}), "Expense", "Utilities", "Wewer", "Non Depreciable", street, city, state, zip];
        let tv = [altFormatNumber({value:data[i].expense.utilities.tv, type:'dollar'}), "Expense", "Utilities", "TV", "Non Depreciable", street, city, state, zip];
        let water = [altFormatNumber({value:data[i].expense.utilities.water, type:'dollar'}), "Expense", "Utilities", "Water", "Non Depreciable", street, city, state, zip];

        csvData.push(
            [],
            ['First up is your Total income recieved.'], 
            totalIncome,
            [],
            ['Your total income is made up of your total TAXABLE income and your total NONTAXABLE income'], 
            totalTaxableIncome, 
            totalNontaxableIncome, 
            [], 
            ['Your total TAXABLE income is from rent, related paychecks, and misc sources'],
            misc, paycheck, rent,
            [],
            ['Your total NONTAXABLE income is deposits (IRS will not tax these if your intent is to return them)'],
            deposits,
            [], 
            [],
            ['Next are all your Expenses'],
            totalExpenses, 
            [],
            ['Of your total Expenses, some you can take as immediate deductions while others you have to depreciate'], 
            totalNondepreciable, 
            totalDepreciableExpenses, 
            [], 
            ['Expenses you have to depreciate are your major repairs or improvements to your properties'],
            majorRepairs, 
            [],
            ['Eveything else you can immediately deduct such as...'],
            [],
            ['General Misc Expenses'],
            miscExp,
            [],
            ['Property Taxes'],
            propertyTaxes,
            [],
            ['Interest Payments'],
            totalInterest,
            [],
            ['Your total basic repairs, made up of misc repairs and appliance/furnishing repairs'],
            totalBasicRepairs, 
            basicRepairs, 
            appliancesAndFurnishingsRepairs, 
            [],
            ['Your total business expenses, made up of misc business expenses, phone, software, and travel'],
            totalBusiness, 
            miscBusiness, 
            phone, 
            software,
            travel, 
            [],
            ['Your total insurance expenses, made up of homeowners, PMI, and umbrella'],
            totalInsurance, 
            homeowners, 
            pmi, 
            umbrella, 
            [],
            ['Your total legal expenses, made up of legal and tax expenses'],
            totalLegalFees, 
            legal, 
            tax, 
            [],
            ['Your total maintenance expenses, made up of misc services, cleaning, post control, and yard card'],
            totalMaintenance,
            servicesMisc,
            cleaning, 
            pestControl, 
            yard, 
            [],
            ['Your total management expenses, made up of HOA, property management, and security'],
            totalManagement, 
            hoa, 
            propertyManagement, 
            security, 
            [],
            ['Your total purchases, made up of appliances, furnishings, and misc supplies'],
            totalPurchases, 
            appliances, 
            furnishings, 
            miscSupplies, 
            [],
            ['Your total utility expenses, made up of electric, gas, internet, sewer, tv, and water'],
            totalUtilities, 
            electric, 
            gas, 
            internet,
            sewer, 
            tv, 
            water,
            [],
            ['You will see this list for each property you own...'],
            [],
            [])
    }

    return csvData;
}

    let label = ''
    if (property.id < 0) {
        label = 'All Properties';
    } else {
        label = property.street + ', ' + property.city + ' ' + property.state;
    }
    if (!data) {
        return <Loading />;
    }  else {

    return (
        <div >

            {props.mobile ? <div className='yearSelectorTaxes'>
                
                    <TextField
                        id="outlined-select-state"
                        select
                        required
                        name='year'
                        label="Year"
                        value={year}
                        onChange={handleChangeYearLocal}
                        className='selectClass'
                        sx={textfieldColorChanges}
                        margin='normal'
                        size={menuSize}
                    >
                        {yearsArr.map((option) => (
                            <MenuItem key={option} value={option}>
                            {option}
                            </MenuItem>
                        ))}
                    </TextField>
                
                </div> : ''} 
            
            <PropertyMenu toggleDrawer={props.toggleDrawer} properties={props.properties} property={property} updateProperty={updateProperty}  refreshSetter={props.refreshSetter} />





        {/* ------- THIS SHOULD BE AN INFO CONTAINER ----------  */}
                <div>
                    <InfoContainer type='taxes' label={label} toggleDrawer={props.toggleDrawer} mobileType={props.mobileType} prepData={prepData} >
                        <TaxesBody  property={property} 
                            toggleDrawer={props.toggleDrawer}
                            refreshSetter={props.refreshSetter}
                            mobile={props.mobile}
                            deviceWidth={props.deviceWidth}
                            yearsArr={props.yearsArr}
                            handleChangeYear={props.handleChangeYear} 
                            year={props.year}
                            propertiesNoPics={props.propertiesNoPics}
                            taxRefresher={props.taxRefresher}
                            data={data}
                            />
                    </InfoContainer>
                </div>
            
        </div>
    )
    }
    
  
};