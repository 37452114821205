import React from 'react';
import { NavLink } from 'react-router-dom';
import './Nav.css';
import logo from './Assets/logo.svg';
import property from './Assets/property.svg';
import propertyActive from './Assets/propertyActive.svg';
import transactions from './Assets/transactions.svg';
import transactionsActive from './Assets/transactionsActive.svg';
import calculator from './Assets/calculator.svg';
import calculatorActive from './Assets/calculatorActive.svg';
import taxes from './Assets/taxes.svg';
import taxesActive from './Assets/taxesActive.svg';
import add from './Assets/add.svg';
import user from './Assets/user.svg';
import hamburger from './Assets/hamburger.svg';
import hamburgerActive from './Assets/hamburgerActive.svg';
import dashboard from './Assets/dashboard.svg';
import dashboardActive from './Assets/dashboardActive.svg';
import logout from './Assets/logout.svg';
import { LoadingSmall } from '../../CoreComponents/Loading/LoadingSmall';
import { useState } from 'react';
import { Logout } from '../../Login/Logout';



export const Nav = (props) => { 

    if (!props.properties) {
        return <LoadingSmall />;
    }

    else
    {
    
let drawerType = 'add';
if (props.properties.length<1){
    drawerType = 'firstProperty'
}

let style = props.type === 'mobile' ? 'container2' : 'container';

    return (
        
        <div style={{minHeight:'100%'}}>           
            <ul className='container2'>

                <li className='hideBullet' onClick={ () => {props.page === 'comingsoon' ? props.pageSetter({response:'comingsoon'}) : props.pageSetter({response:'dashboard'})}}>
                    { props.page === 'dashboard' ?
                    <div className='activeTab'>
                        <img src={dashboardActive} />
                    </div> :
                    <div className='item' >
                        <img src={dashboard} />
                    </div>
                    }   
                </li>
                
                <li className='hideBullet' onClick={ () => {props.page === 'comingsoon' ? props.pageSetter({response:'comingsoon'}) : props.pageSetter({response:'properties'})}}>
                    { props.page === 'properties' ?
                    <div className='activeTab'>
                        <img src={propertyActive} />
                    </div> :
                    <div className='item' >
                        <img src={property} />
                    </div>
                    }   
                </li>

                <li className='hideBullet item addTab'>
                    <img src={add} onClick={() => {props.page === 'comingsoon' ? props.pageSetter({response:'comingsoon'}): props.toggleDrawer({drawerType:drawerType})}} />
                </li>


                <li className='hideBullet' onClick={() => {props.page === 'comingsoon' ? props.pageSetter({response:'comingsoon'}) : props.pageSetter({response:'transactions'})}}>
                    { props.page === 'transactions' ?
                    <div className='activeTab'>
                        <img src={transactionsActive} />
                    </div> :
                    <div className='item' >
                        <img src={transactions} />
                    </div>
                    }  
                </li> 
                
                <li className='hideBullet' onClick={() => props.toggleDrawer({drawerType:'mobileNavDrawer'})}>
                { props.page === 'calculator' || props.page === 'taxes' ?
                    <div className='activeTab'>
                        <img src={hamburgerActive} />
                    </div> :
                    <div className='item' >
                        <img src={hamburger} />
                    </div>
                    }  
                </li>
                
        {/*
                <li className='hideBullet' onClick={() => {props.page === 'comingsoon' ? props.pageSetter({response:'comingsoon'}) : props.pageSetter({response:'calculator'})}}>
                { props.page === 'calculator' ?
                    <div className='activeTab'>
                        <img src={calculatorActive} />
                    </div> :
                    <div className='item' >
                        <img src={calculator} />
                    </div>
                    }  
                </li>

                
                <li className='hideBullet' onClick={() => {props.page === 'comingsoon' ? props.pageSetter({response:'comingsoon'}) : props.pageSetter({response:'taxes'})}}>
                    { props.page === 'taxes' ?
                    <div className='activeTab'>
                        <img src={taxesActive} />
                    </div> :
                    <div className='item' >
                        <img src={taxes} />
                    </div>
                    }  
                </li> 
            
                <li className='item hideBullet'>
                    <img src={user} onClick={() => props.toggleDrawer({drawerType:'account'})}/>
                </li>*/}
                

                
                
            </ul>
            
        </div>
                    
        )
    }
};