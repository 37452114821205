import React from "react";
import { Properties } from './Properties/Properties';
import { PropertiesEmpty } from './PropertiesEmpty/PropertiesEmpty';
import { Loading } from "../CoreComponents/Loading/Loading";
import { PaymentError } from "../CoreStructure/Drawer/PaymentError/PaymentError";
import { Dashboard } from "../Dashboard/Dashboard";


export const PropertiesMain = (props) => {

    if (!props.properties || !props.property || !props.accountStatus || (props.properties.length > 0 && !props.propertiesNoPics && !props.selectedYear && !props.windowName && !props.currentYear && !props.currentMonth)) {
        return <Loading />;
    } else if (props.properties.length < 1) {
        return <PropertiesEmpty toggleDrawer={props.toggleDrawer} type='properties' />;
    } else if (props.accountStatus === 'payment-bad' || (props.accountStatus === 'pretrial' && props.hadTrial === true)) {
        return <PaymentError body='body' />
    } else if (props.page === 'dashboard') {
        return <Dashboard
            toggleDrawer={props.toggleDrawer}
            mobileType={props.mobileType}
            loading={props.loading}
            propertiesRefresher={props.propertiesRefresher}
            loadingSetter={props.loadingSetter}
            properties={props.propertiesNoPics}
            property={props.property}
            year={props.currentYear}
            month={props.currentMonth}
            mobile={props.mobile}
            accountStatus={props.accountStatus}
            hadTrial={props.hadTrial}
            plaidAccount={props.plaidAccount}
            transactionsRefresher={props.transactionsRefresher}
            refreshSetter={props.refreshSetter}
            deviceWidth={props.deviceWidth}
            page={props.page}
            taxRefresher={props.taxRefresher}
        />
    } else {
        return <Properties
            toggleDrawer={props.toggleDrawer}
            properties={props.properties}
            property={props.property}
            updateProperty={props.updateProperty}
            mobileType={props.mobileType}
            windowsArr={props.windowsArr}
            yearsArr={props.yearsArr}
            handleChangeYear={props.handleChangeYear}
            year={props.selectedYear}
            windowName={props.windowName}
            windowNum={props.windowNum}
            loading={props.loading}
            handleChangeCheckbox={props.handleChangeCheckbox}
            propertiesNoPics={props.propertiesNoPics}
            includeCapEx={props.includeCapEx}
            propertiesRefresher={props.propertiesRefresher}
            loadingSetter={props.loadingSetter}
        />
    }
};