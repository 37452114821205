import React from 'react';
import { BarChart, ComposedChart, XAxis, YAxis, Tooltip, CartesianGrid, Cell, Bar, ReferenceLine, ResponsiveContainer } from 'recharts';
import { getColor } from '../../Utilities/functions';
import { yAxisFormatterPercent } from '../CoreStructure/Drawer/Shared/Misc';
import { FormatNumber } from '../CoreComponents/FormatNumber/FormatNumber';

export const ROEVsAssetClasses = (props) => {

    let data = props.data;
    let assetData = data.assetReturns;
    let year = props.currentYear;

    let positive = getColor('--positive');
    let negative = getColor('--negative');
    let positiveLight = getColor('--midGreen');
    let negativeLight = getColor('--midRed');

    let lineColor = getColor('--lineGray')

    let predictedROE = data.EOYPortfolioROE/100;

    let maxROE = Math.max(
        ...assetData.map(item => item.previousYearValue),
        ...assetData.map(item => item.currentYearValue)
      );
      
      let minROE = Math.min(
        ...assetData.map(item => item.previousYearValue),
        ...assetData.map(item => item.currentYearValue)
      );



    if (predictedROE > maxROE) {
        maxROE = predictedROE;
    }
    if (predictedROE < minROE) {
        minROE = predictedROE;
    }

    let YMax = Math.ceil(maxROE)
    let YMin = Math.floor(minROE)
    

    function CustomTooltip({ payload, label, active }) {

        if (active) {
          return (
            <div className="custom-tooltip-wide">
                <div className='label secondaryLabelBlue'>
                    <p className="label">{payload[0].payload.name} Returns</p> 
                </div>
    
               
                <div className='legendRows'>
                    <div className='legendFlexRow'>
                        <p>{year-1} Year End:</p>
                        <p><FormatNumber value={payload[0].value*100} type='percent' colorMe='yes' /></p>
                    </div>

                    <div className='legendFlexRow'>
                        <p>{year} Year to Date:</p>
                        <p><FormatNumber value={payload[1].value*100} type='percent' colorMe='yes' /></p>
                    </div>
                </div>


                <div className='label secondaryLabelBlue'>
                    <p className="label">My Property Return</p> 
                </div>

                <div>
                    <div className='legendFlexRow'>
                        <p>{year} EOY Trending:</p>
                        <p><FormatNumber value={predictedROE*100} type='percent' colorMe='yes' /></p>
                    </div>
                </div>

            </div>
          );
        }
        return null;
    }






    return (
        <div>
            <ResponsiveContainer width="100%" height={500} >
                    <ComposedChart data={assetData} margin={{ top: 20, right: 20, left: 20, bottom: 20 }} background={false}>
                    <Bar dataKey="previousYearValue" >
                        {assetData.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={entry.previousYearValue >= 0 ? positiveLight : negativeLight}
                        />
                        ))}
                    </Bar>
                    <Bar dataKey="currentYearValue" >
                        {assetData.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={entry.currentYearValue >= 0 ? positive : negative}
                        />
                        ))}
                    </Bar>
                    
                    <XAxis dataKey="name" 
                        tickMargin='25'
                        interval={0}
                        angle = '-45'
                        height={75}/>
                    <YAxis 
                        tickFormatter={yAxisFormatterPercent} 
                        domain={[YMin, YMax]} 
                        type='number'
                          tickSize='8'
                          tickCount='5' />
                    <Tooltip content={<CustomTooltip />} />
                    
                    <ReferenceLine y={0}  stroke={lineColor} />
                    <ReferenceLine 
                        y={predictedROE} 
                        /*label={{ 
                            value: `Your trending ${year} Return on Equity`, 
                            position: "insideTop",  // You can use different positions like 'top', 'bottom', etc.
                            style: { fontStyle: "italic" }
                        }}  */
                        stroke={predictedROE >= 0 ? positive : negative } 
                        strokeDasharray="7 7" 
                    />
                    
                </ComposedChart>
            </ResponsiveContainer>

            <div className='tertiaryLabelLight legendFlexRow legendMargin'>
                <div className='legendFlexRow'>
                    <div className='square' style={{ backgroundColor: negativeLight }}></div>
                    <div className='square' style={{ backgroundColor: positiveLight }}></div>
                    <p>Last Year</p>
                </div>
                <div className='legendFlexRow'>
                    <div className='square' style={{ backgroundColor: negative }}></div>
                    <div className='square' style={{ backgroundColor: positive }}></div>
                    <p>Current Y2D</p>
                </div>
                <div className='legendFlexRow'>
                    <p style={{ color: predictedROE >= 0 ? positive : negative }}>- - -</p>
                    <p>My trending ROE</p>
                </div>
                
            </div>
        </div>
        )

}