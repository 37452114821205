import React, {useState, useEffect} from 'react';
import { CustomButton } from "../../../CoreComponents/CustomButton/CustomButton";
import './AccountRouting.css';

import EditGray from '../../../CoreStructure/InfoContainer/Assets/EditGray.svg';

import {PUT, GET, DELETE} from "../../../../Utilities/networkRequests";
import { deleteTransactions, getAccount } from '../../../../api/properties';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import TextField from '@mui/material/TextField';

import { PropertyTab } from '../../../PropertiesMain/Properties/PropertyTab/PropertyTab';

import {PlaidUpdateModal } from '../../../Plaid/Link/Link';

import { formatDate, textfieldColorChanges } from "./../Shared/Misc.js";
import { LoadingSmall } from '../../../CoreComponents/Loading/LoadingSmall.js';

export const EditRouting = (props) => {
    let newDate = new Date();
    let date=formatDate({date:newDate, format:'ymd'})
    

    const [account, setAccount] = useState(null)
    const [value, setValue] = useState('');
    const [removeDate, setRemoveDate]= useState(date);

    const [plaidUpdateLinkToken, setPlaidUpdateLinkToken] = useState('')


    let properties = props.properties;
    const [property, setProperty] = useState({id:-1});

    useEffect(() => {
    getAccount({accountID:props.accountID}).then((res) => {
        if (res.properties_id === -1) {
            setValue('c');
        } else if (res.properties_id === -2) {
            setValue('b');
        } else if (res.properties_id > 0){
            setValue('a');
        } else {

        }
        setProperty({id:res.properties_id});
        setAccount(res);
        receiveDuplicateAccount(res.institution_id);
       });
    }, []);

    let waybozGreen = "#03B09B";

    async function receiveDuplicateAccount(itemID){
        const response = await GET(`/plaid/create-link-token-update?item_id=${itemID}`)

        if (response.data){
            setPlaidUpdateLinkToken(response.data.link_token)
        }
    }
    
    const handleClick = ({id}) => {
        let newProp;
        if (id === -1){
            newProp = {id:-1};
        } else if (id === -2) {
            newProp = {id:-2};
        } else if (id === -3) {
            newProp = {id:-3};
        }
        else {
            let index = properties.findIndex((prop) => prop.id === id);
            newProp = properties[index];
        }
        setProperty(newProp);
    }


    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleChangeDate = (e) => {
        setRemoveDate(e.target.value);
    };

    let handleSubmit = async () => {
        let arr = [];
        
        if (value === 'a'){
            arr.push(property.id)
        } else if (value === 'b') {
            arr.push(-2)
        } else {
            arr.push(-1)
        }
            

            
        arr.push(account.account_db_id);   
        
        let res = await PUT(`/plaid/availableAccounts`, {
            arr
        });

        props.refreshSetter({type:'editAccount'});

    }
  
  


let selectionStatus=false;
    let allPropsSelected=false;
    let unassignedSelected=false;

    let personalSelected = false;
    if (property.id === -1) {
        allPropsSelected=true;
    } else if (property.id === -2){
        unassignedSelected=true;
    } else if (property.id === -3){
        personalSelected=true;
    }
    else {
        allPropsSelected=false;
        unassignedSelected=false;
    }

let showProperties = properties.map((item, index) => {
    if (item.id === property.id) {
         selectionStatus=true;
    } else {
         selectionStatus=false;
    }
     return (<div key={index} className='individualTab'><PropertyTab
                key={index}
                street={item.street}
                city={item.city}
                state={item.state}
                onClick={() => handleClick ({id:item.id})}
                selectionStatus={selectionStatus}
                image={item.street_view}
                id = {item.id}
                included={true}
            /></div>)
});

let handleRemove = async (e) => {
    let accountID = props.accountID; 
    if (window.confirm(`This will remove your connection to ${account.institution_name} entirely. However, all transactions previously connected to this account will be retained. Are you sure you want to continue?`)) {
        props.toggleDrawer({drawerType:''});
        let res = await DELETE(`/plaid/institution/${accountID}/true`); 
        props.refreshSetter({type:'editAccount'});
        //window.location.reload(false);
    } 
}

let handleRemoveWithData = async (e) => {
    let accountID = props.accountID; 
    if (window.confirm(`This will remove your connection to ${account.institution_name} entirely. This deletes all accounts and transactions that you have linked. Are you sure you want to continue?`)) {
        props.toggleDrawer({drawerType:''});
        let res = await DELETE(`/plaid/institution/${accountID}/false`); 
        props.refreshSetter({type:'editAccount'});
        //window.location.reload(false);
    } 
}

let handleRemoveTransactions = async (e) => {
    let accountID = props.accountID; 
    if (window.confirm(`This remove all transactions for ${account.institution_name} before ${removeDate}. This cannot be undone. Are you sure you want to continue?`)) {
        props.toggleDrawer({drawerType:''});
        let res = await deleteTransactions({accountID: accountID, date: removeDate}); 
        props.refreshSetter({type:'editAccount'});
    } 
}

  if (!account) {
    return <LoadingSmall />
  } else {

    return (
        <form className='drawerContainer2' id="edit-account-container" onSubmit={(e) => {
            handleSubmit();
            e.preventDefault();
            props.toggleDrawer({drawerType:''});
            }}>

            <div className='scrollControl'>
                <p className='primaryLabel primaryTitle'>Edit Account</p>
                <div className='center'>
                    <img className='editImg' src={EditGray} />
                </div>
                
               
                <div className='accountContainer containerDisplay backgroundAccout'>
                    <div className='accountGroup slightRightMargin'>
                        <div className='secondaryLabelBlueSemi'><p>{account.institution_name}</p></div>
                        <div className='numNameContainer secondaryLabelGray'><p>{account.name}</p><p className='slightLeftMargin'> #xxxxxx{account.account_num}</p></div>
                    </div>
                    <div>
                    {plaidUpdateLinkToken ? <PlaidUpdateModal publicToken={plaidUpdateLinkToken} type='transaction' update={true} toggleDrawer={props.toggleDrawer} refreshSetter={props.refreshSetter} /> : ""}
                    </div>
                </div>
                



                <div className='margTop'>
                    <p className='secondaryLabelBlue secondaryTitle'>ALL TRANSACTIONS FOR THE ACCOUNT(S) (select one):</p>
                    <div className='buttonMargin'>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                        sx={{
                            "& .MuiRadio-root": { color: waybozGreen },
                            "& .MuiRadio-root.Mui-checked": { color: waybozGreen },
                          }}
                    >
                        <FormControlLabel value="a" control={<Radio required={true} />} label="Are for just ONE property" />
                        <FormControlLabel value="b" control={<Radio required={true} />} label="Are solely personal or NON-PROPERTY transactions " />
                        <FormControlLabel value="c" control={<Radio required={true} />} label="Neither of the above" />
                    </RadioGroup>
                    </div>
                </div>


                {
                    value === 'c' ?
                    <div className='secondaryLabelBlueSemi margTop' style={{textAlign:'center'}}>
                        <p>Perfect, so transactions for this account are either for multiple properties or might include non-property income or expenses too.</p> 
                        <br />
                        <p>We’ve made it so you can very quickly tag new transactions by property or type so all your metrics stay accurate!</p>
                    </div> 
                    : 
                    value === 'b' ? 
                    <div className='secondaryLabelBlueSemi margTop' style={{textAlign:'center'}}>
                        <p>Great! We’ll auto-tag all these transactions as “personal” so they won’t impact your property cashflows</p> 
                    </div> 
                    :
                    value === 'a' ? 
                    <div className='secondaryLabelBlueSemi margTop'>
                        <p>Great - which property?</p>
                        <div className='propTab'> {showProperties} </div>
                       
                    </div>
                    : ''
                }
           
            
                <div >
                    <div className='accountContainer containerDisplay labelMargin'>
                        <p className='secondaryLabelBlue maxWidth'>DELETE ALL TRANSACTIONS FOR THIS ACCOUNT BEFORE: </p> 
                    </div>
                    <p className='bodyTextGray pTweaks maxWidth'>Did you upload a spreadsheet and now have no need for certain transactions from this account from before a certain date? Just select the date and we'll permanently remove them.</p>
                    <div className='accountContainer containerDisplay dateRowMargin'>
                        <TextField
                            required
                            id="standard-read-only-input"
                            label="Date"
                            name='purchase_date'
                            type='date'
                            value={formatDate({date:removeDate, format:'ymd'})}
                            variant="outlined"
                            className='dateWidth'
                            onChange={handleChangeDate}
                            margin='normal'
                            sx={textfieldColorChanges} 
                                        />
                        <p className='removeButton' onClick={(e) => handleRemoveTransactions()} >Remove Now</p>
                    </div>
                </div>


            

            </div>
            <div>
                
                <p className='removeAccount moreMargin' onClick={(e) => handleRemove()}>Disconnect {account.institution_name} Accounts but RETAIN Transaction Data </p>

                <p className='removeAccount moreMargin' onClick={(e) => handleRemoveWithData()}>Disconnect and Delete ALL {account.institution_name} Accounts & Data </p>
                <CustomButton type='submit' >DONE</CustomButton>
            </div>
        </form>
    )

}
}