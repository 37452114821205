import React from 'react';
import { FormatNumber } from '../CoreComponents/FormatNumber/FormatNumber';

export const TaxLiability = (props) => {

    let data = props.data;
    let year = props.currentYear;
    let taxableIncome = data[0].income.taxable + data[0].expense.nondepreciable;

    return (
        <div className = 'dashboardDataContainer bodyTextBlue'>
            <div className='secondaryLabelBlueSemi taxFlex'>
                <p >{year} Taxable Income: </p>
                <FormatNumber value={taxableIncome} type='dollar' colorMe='yes' />
            </div>
            <p className='tertiaryLabelLight'><em>* Does not include depreciation savings. Always consult a licensed accountant for exact tax responsibilities.</em></p>
        </div>
    )

}