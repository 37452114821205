import React from 'react';
import { FormatNumber } from '../CoreComponents/FormatNumber/FormatNumber';
import { NeedleChart } from './NeedleChart';
import { getColor } from '../../Utilities/functions';

export const CashFlowVsPersonal = (props) => {

    let data = props.data;

    let positive = getColor('--positive');
    let negative = getColor('--negative');
    let cashFlow = getColor('--cashFlow');

    return (
        <div className = 'dashboardDataContainer bodyTextBlue'>
            
            <div className = 'dashboardDataContainerHalf1 labelAndNum'>
                <div className='labelCol'>
                    <div className='circleAndLabel'>
                        <div className='circle' style={{ backgroundColor: cashFlow }}></div>
                        <p>Portfolio Cash Flow:</p>
                    </div>
                    <div className='circleAndLabel borderBottomLine'>
                        <div className='circle' style={{ backgroundColor: negative }}></div>
                        <p>Personal Expenses:</p>
                    </div>
                    <div className='circleAndLabel'>
                        <div className='circle' style={{ backgroundColor: positive }}></div>
                        <p className='secondaryLabelBlue'>Leftover Cash Flow:</p>
                    </div>
                </div>
                <div className='numCol'>
                    <p><FormatNumber value={data.Y2DPortfolioCashFlow} type='dollar' /></p>
                    <p className='borderBottomLine' ><FormatNumber value={data.Y2DPersonalExpenses} type='dollar' /></p>
                    <p className='secondaryLabelBlue'><FormatNumber value={data.Y2DDiff} type='dollar' colorMe='yes' /></p>
                </div>
                
            </div>

            <div className = 'dashboardDataContainerHalf2 needleChart'>
                {data.Y2DPortfolioCashFlow < 0 ? <p><em>Oops! Your cash flow is negative! It obviously can't cover personal expenses. <span>:&nbsp;(</span></em></p>
                : data.Y2DPortfolioCashFlow === 0 ? <p><em>Oops! Your cash flow isn't above zero! There's nothing to show here. <span>:&nbsp;(</span></em></p>
                :
                <NeedleChart 
                    cashflow = {data.Y2DPortfolioCashFlow} 
                    personalExpenses = {data.Y2DPersonalExpenses}
                    diff = {data.Y2DDiff} />}
            </div>
            
            
        </div>
        
    )

}