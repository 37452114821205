import './App.css';
import { Header } from '../CoreStructure/Header/Header';
import { Nav } from '../CoreStructure/Nav/Nav';
import { PropertiesMain } from '../PropertiesMain/PropertiesMain';
import { Transactions } from '../Transactions/Transactions';
import { CalculatorContainer } from '../Calculator/CalculatorContainer';
import { useState, useEffect } from 'react';
import { Drawer } from '../CoreStructure/Drawer/Drawer';
import { NavDrawer } from './../CoreStructure/Nav/NavDrawer';
import { getProperties, getNewPlaidTransactions, startupItems, createShortTrial, updateTrial, addStreetViewImage, getPropertyStats2, getTaxData } from '../../api/properties';
import { ComingSoon } from '../ComingSoon/ComingSoon';
import { GET } from '../../Utilities/networkRequests';
import { PlaidReconnectModal } from '../Plaid/Link/Link'
import { TaxesLoad } from '../Taxes/TaxesLoad';
import { getWindowNumByName, getWindowNameByNum } from '../../Utilities/functions'


export const Main = (props) => {

  //ensures drawer begins closed and stablishes a bunch of misc and poorly constructed key/value pairs to pass data into
  const [drawerOpen, setDrawerOpen] = useState({ status: false, type: '', id: '', month: '', accountID: '', accounts: [], addAccount: false, plaidToken: '', institution: '', transInfo: {}, newProperty: {}, misc: {}, window: '', year: '', payload: {} });

  // ensures navdrawer begins closed
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);

  // will eventually store high level property data for user (if they have properties)
  const [properties, setProperties] = useState();

  // currently storing property picture in DB (I don't know what I am doing) and it takes up a ton of space so created a version of properties object without the picture
  const [propertiesNoPics, setPropertiesNoPics] = useState();

  // this denotes which property is actually currently selected (propertyID of -1 if all properties are selected as opposed to an individual one)
  const [property, setProperty] = useState();

  // ????????? I don't remember what this is currently...?????????????
  const [plaidAccount, setPlaidAccount] = useState(-1);

  // this denotes which page is selected and is used below to determine which page component to show in main body of the app
  const [page, setPage] = useState('dashboard');

  // this denotes whether loading screen should be shown in place of current page (due to data being retrieved)
  const [loading, setLoading] = useState(true);

  // this determines whether the plaid error toast should be visibile or not
  const [showPlaidErrAlert, setShowPlaidErrAlert] = useState(false);

  // something about plaid...
  const [plaidReconnectLinkToken, setPlaidReconnectLinkToken] = useState('')

  // this denotes what year is currently selected for PROPERTY STATS page
  const [selectedYearStats, setSelectedYearStats] = useState();

  // this denotes what window is currently selected for PROPERTY STATS page
  const [selectedWindowNum, setSelectedWindowNum] = useState();

  // this denotes what window is currently selected for PROPERTY STATS page
  const [selectedWindowName, setSelectedWindowName] = useState();

  // current month/window in format needed for BE
  const [currentMonth, setCurrentMonth] = useState();

  //current year
  const [currentYear, setCurrentYear] = useState();

  // this denotes whether cap ex should be included in PROPERTY STATS calculations
  const [includeCapEx, setIncludeCapEx] = useState(false);

  // this is the property data to be displayed in PROPERT STATS page... moving there
  // const [propertyData, setPropertyData] = useState();

  // this denotes which years should be available in year selectors for PROPERTY STATS and TAXES pages
  const [yearsArr, setYearsArr] = useState();

  // this denotes what windows are available to choose from in PROPERTY STATS page
  const [windowsArr, setWindowsArr] = useState([
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    'Spring (Mar - May)',
    'Summer (Jun - Aug)',
    'Fall (Sep - Nov)',
    'Winter (Dec - Feb)'
  ])

  // this denotes what year is currently selected for TAXES page
  const [selectedYearTaxes, setSelectedYearTaxes] = useState();

  // this denotes whether the account is in good status or not
  const [accountStatus, setAccountStatus] = useState('');

  // i dont remember how we use this at the moment... but something probably about whether or not they can have another trial or not 
  const [hadTrial, setHadTrial] = useState(false);

  //this is used to refresh part of app when other parts change, the refreshsetter is passed to wherever a user can input / change something and is called on submit, when it is called, it sets refresher state to its opposite, refresher state is passed to various components and used in useEffect to rerender page when refreshsetter called
  const [propertiesRefresher, setPropertiesRefresher] = useState(false);
  const [institutionsRefresher, setInstitutionsRefresher] = useState(false);
  const [equityRefresher, setEquityRefresher] = useState(false);
  const [cashflowRefresher, setCashflowRefresher] = useState(false);
  const [transactionsRefresher, setTransactionsRefresher] = useState(false);
  const [taxRefresher, setTaxRefresher] = useState(false);





  // ---------------------- BEGIN STARTUP ITEMS / USE EFFECT STUFF -----------------------------------


  // this gets all the initial data we will need for a user, particularly their properties they have added
  useEffect(() => {
    getProperties().then((res) => {

      // most everything in here only occurs if the user has at least one property already added
      if (res.length > 0) {

        // when use logs in we won't have a property, but we can't default to this incase the user has NO properties
        // if user has properties, but property isn't yet set, we set to -1 which is all properties
        if (!property) {
          setProperty({ id: -1 });

          //however, this useEffect can get triggered again when property changes happen, like deleting a property
          // we reget properties, and if property is already set, we want to see if that property still exists with our latest request
          // if it no longer exists (because it might have just been deleted), we change the set property to be all properties or -1
        } else {
          let find = res.find(item => item.id === property.id);
          if (!find) {
            setProperty({ id: -1 });
          }
        }

        // some properties won't have a street view image, either because google didnt have it or they initially entered the address wrong
        // every this effect gets called, if the property doesnt have an image, we look for one
        res.forEach(async property => {
          if (!property.street_view) {
            const data = await addStreetViewImage({ street: property.street, city: property.city, state: property.state, propertyID: property.id });
            // if they have a bad address, this might call the google API more than i need to so might want to remove this in the future...
            //technically should update res here before setProperties... but won't really matter...
          }
        })


        // when user first logs in, we won't have a specific time period (year or window) yet selected by them to get data for
        // we need to default to the current month and year until user manually changes
        // below code determines what that current month and year is
        // it leverages getInitialWindow function b/c the app is a mess and uses strings, and numbers, and numbers as strings throughout with how to denote windows
        // below also determines how many years should appear in the year dropdown menu for the user to select from
        // the oldest year is determined by the oldest purchase date
        if (!selectedYearStats || !selectedYearTaxes || !selectedWindowName) {
          let newDate = new Date();
          let currentYear = newDate.getFullYear();
          let currentMonth = newDate.getMonth() + 1;
          setSelectedYearStats(currentYear);
          setSelectedYearTaxes(currentYear);
          setCurrentYear(currentYear);
          setCurrentMonth(currentMonth);
          setSelectedWindowNum(currentMonth);
          setSelectedWindowName(getWindowNameByNum(currentMonth));
          let currentMin = currentYear;
          res.map((item) => {
            let purchaseDate = new Date(item.purchase_date);
            let year = purchaseDate.getFullYear();
            if (year < currentMin) {
              currentMin = year;
            }
          })
          let years = [];
          for (let i = currentYear; i > currentMin - 1; i--) {
            years.push(i);
          }
          setYearsArr(years);
        }



        // creates a newPropertiesArr but without the street view for request to BE b/c my street view images are huge and stored in the DB b/c I don't know how to do it the right way
        const newArray = res.map(obj => {
          const newObj = { ...obj };
          delete newObj.street_view;
          return newObj;
        });
        setPropertiesNoPics(newArray);




        /*
          // this gets all the data required for the PROPERTY STATS page, i moved it to the property stats component since the app doesnt open to this page now
          if (selectedYearStats && selectedWindow) {
            // defaults to setLoading to true because this takes a long time, lots of data crunching
            setLoading(true);
            getPropertyStats2({ properties: newArray, year: selectedYearStats, includeCapEx: includeCapEx, window: selectedWindow }).then((res) => {
              setPropertyData(res);
              setLoading(false);
            });
          }*/

      } else {
        // if user doesn't have any properties, we set property to -1, all properties, anyway...
        setProperty({ id: -1 });
      }

      // properties state is update to be the response of getting properties
      // it is possible the res is (maybe) null, which sets properties to null
      // downstream, if properties is null, it doesn't try to show property data, it just keeps prompting the user to add a property
      setProperties(res);


    })
    // a handful of actions retrigger this entire bit of chaos
  }, [propertiesRefresher]);



  /*
  // this used to open the drawer when user first logs in to prompt them to pick an area to start
  // this will be removed as the user will now default to the newly added dashboard page
    useEffect( () => {
        
      toggleDrawer({drawerType:'whereStart'})
    }, []);*/


  // this calls a function that calls the BE to get any new plaid transactions for the user
  // if one of the users plaid accounts has a connection issue, this makes it so user can see the reconnect modal
  useEffect(() => {
    getNewPlaidTransactions().then((res) => {

      if (Array.isArray(res.data)) {
        setPlaidReconnectLinkToken(res.data[0].link_token)
        setShowPlaidErrAlert(true)
      }
    })
  }, []);




  // this calls a function that calls an endpoint that does a few account management and analytics things each time the user logs in
  // it determines whether the user is in good standing with the account (in trial or paid up)
  // it updates some analytics data in the DB (eg, last login time, increment app usage metrics, etc)
  useEffect(() => {
    startupItems().then(async res => {

      if (res?.trial_status === 'payment-bad') {
        toggleDrawer({ drawerType: 'paymentError' })
      }

      setHadTrial(res.had_trial)
      setAccountStatus(res.trial_status)

    });

  }, []);

  // ------------- END STARTUP ITEMS / USE EFFECT STUFF -----------------------------------







  // --------------------- FUNCTIONS PASSED DOWNSTREAM TO SET STATE ESTABLISHED UP HERE -------------------------

  // not sure if this is used at all.... doesn't look like it?
  async function generatePlaidReconnectLink(itemID) {
    if (itemID) {
      const response = await GET(`/plaid/create-link-token-reconnect?item_id=${itemID}`)

      if (response.data) {
        setPlaidReconnectLinkToken(response.data.link_token)
      }
    }
  }

  // setter function for when selected property changes
  let updateProperty = ({ property }) => {
    setProperty(property);
  }

  // setter function for when selected plaid account changes?
  let updatePlaidAccount = ({ accountID }) => {
    setPlaidAccount(accountID);
  }

  // this is the global function to open and close the side drawer
  // the else if is when the drawer is already open and we want to switch drawer screens but keep it open
  // this is a mess and really doesnt need all the different key value pairs options
  const toggleDrawer = ({ drawerType, id, month, accounts, accountID, addAccount, plaidToken, institution, transInfo, newProperty, misc, window, year, payload }) => {
    if (!drawerOpen.status) {
      setDrawerOpen({ status: true, type: drawerType, id: id, month: month, accounts: accounts, accountID: accountID, addAccount: addAccount, plaidToken: plaidToken, institution: institution, transInfo: transInfo, misc: misc, window: window, year: year, payload: payload });
    } else if (drawerType === 'plaid' || drawerType === 'plaidexplainer' || drawerType === 'loadingPlaid' || drawerType === 'accountRouting' || drawerType === 'manageInstitution' || drawerType === 'splitTransaction' || drawerType === 'startTrial' || drawerType === 'propertyTransactions' || drawerType === 'propertyMortgage' || drawerType === 'firstProperty' || drawerType === 'tooltip' || drawerType === 'add' || drawerType === 'account') {
      // this else if is for when the drawer is already open and we are opening a new drawer
      setDrawerOpen({ status: true, type: drawerType, id: id, month: month, accounts: accounts, accountID: accountID, addAccount: addAccount, plaidToken: plaidToken, institution: institution, transInfo: transInfo, newProperty: newProperty, misc: misc, window: window, year: year, payload: payload });
    } else {
      setDrawerOpen(false);
    }
  }

  // this is the global function that opens and closes the nav drawer on hover and such
  const toggleNavDrawer = () => {
    if (!navDrawerOpen) {
      setNavDrawerOpen(true);
    } else {
      setNavDrawerOpen(false);
    }
  }

  // setter function for when select app page changes (from clicking on nav items)
  const pageSetter = ({ response }) => {
    setPage(response);
  }

  // setter function for when select app page changes (from clicking on nav items)
  const loadingSetter = ({ response }) => {
    setLoading(response);
  }

  // if property data changes downstream, we need a way to update the properties state. This does that
  const updatePropertyData = ({ propertyID, property }) => {
    let index = properties.findIndex((e) => e.id === propertyID);
    let newProperties = [...properties];
    newProperties[index] = property;
    setProperties(newProperties);
  }

  // global function to manage changing year state that is declared at Main.js
  let handleChangeYear = ({ e, type }) => {
    if (type === 'taxes') {
      setSelectedYearTaxes(e.target.value);
    } else if (type === 'stats') {
      setSelectedYearStats(e.target.value);
    } else {
      setSelectedWindowName(e.target.value);
      setSelectedWindowNum(getWindowNumByName(e.target.value))
    }
  }

  // this accounts for any changes to whether PROPERTY STATS data should be including capex or not
  let handleChangeCheckbox = () => {
    setIncludeCapEx(!includeCapEx);
  }

  // global refresh setter function used across the app
  const refreshSetter = ({ type }) => {
    if (type === 'newTrans') {
      setCashflowRefresher(!cashflowRefresher);
      setTransactionsRefresher(!transactionsRefresher)
      setPropertiesRefresher(!propertiesRefresher);
    }

    if (type === 'tax') {
      setTaxRefresher(!taxRefresher)
      setCashflowRefresher(!cashflowRefresher);
      setPropertiesRefresher(!propertiesRefresher);
    }

    if (type === 'newProperty') {
      setPropertiesRefresher(!propertiesRefresher);
      setCashflowRefresher(!cashflowRefresher);
      setEquityRefresher(!equityRefresher);
      setTransactionsRefresher(!transactionsRefresher)
    }

    if (type === 'newProperty2') {
      setPropertiesRefresher(!propertiesRefresher);
    }

    if (type === 'newMortgage') {
      setEquityRefresher(!equityRefresher);
      setPropertiesRefresher(!propertiesRefresher);
    }

    if (type === 'newMortgage2') {
      setEquityRefresher(!equityRefresher);
    }

    if (type === 'deletePropValue') {
      setPropertiesRefresher(!propertiesRefresher);
      setEquityRefresher(!equityRefresher);
    }

    if (type === 'editProperty') {
      setPropertiesRefresher(!propertiesRefresher);
      setEquityRefresher(!equityRefresher);
      setCashflowRefresher(!cashflowRefresher);
    }

    if (type === 'deleteProperty') {
      // this is to make sure user cant hit trial again if status didnt refresh when adding first property 
      if (accountStatus === 'pretrial') {
        startupItems().then(res => setAccountStatus(res.trial_status));
      }
      setPropertiesRefresher(!propertiesRefresher);
      setCashflowRefresher(!cashflowRefresher);
      setEquityRefresher(!equityRefresher);
    }

    if (type === 'newAccount') {
      setTransactionsRefresher(!transactionsRefresher);
      setInstitutionsRefresher(!institutionsRefresher);
    }

    if (type === 'editAccount') {
      setCashflowRefresher(!cashflowRefresher);
      setInstitutionsRefresher(!institutionsRefresher);
      setPlaidAccount(-1)
      setTransactionsRefresher(!transactionsRefresher);
    }

    if (type === 'editTransactions') {
      setCashflowRefresher(!cashflowRefresher);
      setPropertiesRefresher(!propertiesRefresher);
    }

    if (type === 'deleteTransactions') {
      setTransactionsRefresher(!transactionsRefresher);
      setPropertiesRefresher(!propertiesRefresher);
    }
  }
  // --------------------- END FUNCTIONS PASSED DOWNSTREAM TO SET STATE ESTABLISHED UP HERE -------------------------





  // -------------  Screen size stuff used for component and css class logic downstream -------------
  let deviceWidth = props.width;
  const breakpointLarge = 800;
  const breakpointMedium = 600;
  const breakpointSmall = 500
  const breakpointXSmall = 400

  let mobile = deviceWidth < breakpointLarge ? true : false;
  let mobileType = 'small';
  if (deviceWidth < breakpointLarge && deviceWidth >= breakpointMedium) {
    mobileType = 'large';
  } else if (deviceWidth < breakpointMedium && deviceWidth >= breakpointSmall) {
    mobileType = 'medium';
  } else if (deviceWidth < breakpointSmall && deviceWidth >= breakpointXSmall) {
    mobileType = 'small';
  } else {
    mobileType = 'xsmall';
  }
  // ------------- End Screen size stuff -------------------------------------------


  // ------------------------- END SETUP STUFF --------------------------








  // ------------------------- ON TO THE COMPONENTS ----------------------

  return (

    <>
      {deviceWidth < breakpointLarge ?
        <div>
          <Drawer
            type={drawerOpen.type}
            status={drawerOpen.status}
            toggleDrawer={toggleDrawer}
            updatePropertyData={updatePropertyData}
            propertyID={drawerOpen.id}
            properties={properties}
            month={drawerOpen.month}
            window={drawerOpen.window}
            year={drawerOpen.year}
            payload={drawerOpen.payload}
            accounts={drawerOpen.accounts}
            accountID={drawerOpen.accountID}
            addAccount={drawerOpen.addAccount}
            refreshSetter={refreshSetter}
            plaidToken={drawerOpen.plaidToken}
            institution={drawerOpen.institution}
            transInfo={drawerOpen.transInfo}
            newProperty={drawerOpen.newProperty}
            mobile={mobile}
            mobileType={mobileType}
            accountStatus={accountStatus}
            hadTrial={hadTrial}
            property={property}
            misc={drawerOpen.misc}
            pageSetter={pageSetter}
            page={page}
          />
          <div >

            <div className='mobileHeader'><Header properties={properties} cashflowRefresher={cashflowRefresher} equityRefresher={equityRefresher} mobile={mobile} page={page} mobileType={mobileType} /></div>
            <div className='mobileContainer' style={{ textAlign: 'center' }}>
              <div className='mobileMain scrollControlMobile'>
              <div className='properties'>
                {page === 'transactions' ? <Transactions toggleDrawer={toggleDrawer}
                  properties={properties}
                  property={property}
                  plaidAccount={plaidAccount}
                  updatePlaidAccount={updatePlaidAccount}
                  transactionsRefresher={transactionsRefresher}
                  institutionsRefresher={institutionsRefresher}
                  refreshSetter={refreshSetter}
                  mobile={mobile}
                  accountStatus={accountStatus}
                  hadTrial={hadTrial}
                  page={page}
                  perPage={50}
                />
                  : page === 'calculator' ? <CalculatorContainer toggleDrawer={toggleDrawer} mobileType={mobileType} properties={props.properties}/>
                    : page === 'comingsoon' ? <ComingSoon pageSetter={pageSetter} type='desktop' />
                      : page === 'taxes' ? <TaxesLoad properties={properties}
                        property={property}
                        mobile={mobile}
                        deviceWidth={deviceWidth}
                        accountStatus={accountStatus}
                        hadTrial={hadTrial}
                        yearsArr={yearsArr}
                        handleChangeYear={handleChangeYear}
                        year={selectedYearTaxes}
                        propertiesNoPics={propertiesNoPics}
                        toggleDrawer={toggleDrawer}
                        taxRefresher={taxRefresher}
                        refreshSetter={refreshSetter}
                      />
                        : <PropertiesMain toggleDrawer={toggleDrawer}
                          properties={properties}
                          property={property}
                          updateProperty={updateProperty}
                          mobile={mobile}
                          mobileType={mobileType}
                          accountStatus={accountStatus}
                          hadTrial={hadTrial}

                          windowsArr={windowsArr}
                          yearsArr={yearsArr}
                          handleChangeYear={handleChangeYear}
                          selectedYear={selectedYearStats}
                          windowName={selectedWindowName}
                          windowNum={selectedWindowNum}
                          loading={loading}
                          handleChangeCheckbox={handleChangeCheckbox}
                          propertiesNoPics={propertiesNoPics}
                          includeCapEx={includeCapEx}
                          page={page}
                          propertiesRefresher={propertiesRefresher}
                          loadingSetter={loadingSetter}

                          currentYear={currentYear}
                          currentMonth={currentMonth}
                          taxRefresher={taxRefresher}

                          plaidAccount={plaidAccount}
                          transactionsRefresher={transactionsRefresher}
                          refreshSetter={refreshSetter}
                          deviceWidth={deviceWidth}
                        />
                }
                </div>


              </div>
            </div>
            {<div className='mobileNav mobileFooter'><Nav toggleDrawer={toggleDrawer} pageSetter={pageSetter} page={page} properties={properties} type={'mobile'} /></div>}
          </div>

          {showPlaidErrAlert ?
            <div className='plaidErrAlert'>
              <p className='headerMetric alertPadding'>Error getting transactions from your bank account. <PlaidReconnectModal publicToken={plaidReconnectLinkToken} /></p>
            </div>
            : <div className='plaidErrAlert newTransAlertHidden'><p className='headerMetric negative alertPadding'>Error getting transactions from your bank account. <PlaidReconnectModal /></p></div>
          }
        </div>
        :
        <div>
          <Drawer
            type={drawerOpen.type}
            status={drawerOpen.status}
            toggleDrawer={toggleDrawer}
            updatePropertyData={updatePropertyData}
            propertyID={drawerOpen.id}
            properties={properties}
            month={drawerOpen.month}
            window={drawerOpen.window}
            year={drawerOpen.year}
            payload={drawerOpen.payload}
            accounts={drawerOpen.accounts}
            accountID={drawerOpen.accountID}
            addAccount={drawerOpen.addAccount}
            refreshSetter={refreshSetter}
            plaidToken={drawerOpen.plaidToken}
            institution={drawerOpen.institution}
            transInfo={drawerOpen.transInfo}
            newProperty={drawerOpen.newProperty}
            mobile={mobile}
            accountStatus={accountStatus}
            hadTrial={hadTrial}
            property={property}
            misc={drawerOpen.misc}
            pageSetter={pageSetter}
            page={page}
          />

          <NavDrawer status={navDrawerOpen} toggleDrawer={toggleDrawer} toggleNavDrawer={toggleNavDrawer}
            pageSetter={pageSetter} page={page} properties={properties} />
          <div className="App">
            <div className='header'><Header properties={properties} cashflowRefresher={cashflowRefresher} equityRefresher={equityRefresher} mobile={mobile} page={page} mobileType={mobileType} /></div>

            <div className='body'>
              <div className='properties'>
                {page === 'transactions' ? <Transactions toggleDrawer={toggleDrawer}
                  properties={properties}
                  property={property}
                  plaidAccount={plaidAccount}
                  updatePlaidAccount={updatePlaidAccount}
                  transactionsRefresher={transactionsRefresher}
                  institutionsRefresher={institutionsRefresher}
                  refreshSetter={refreshSetter}
                  mobile={mobile}
                  deviceWidth={deviceWidth}
                  accountStatus={accountStatus}
                  hadTrial={hadTrial}
                  page={page}
                  perPage={50}
                  />
                  : page === 'calculator' ? <CalculatorContainer toggleDrawer={toggleDrawer} mobileType={mobileType} />
                    : page === 'comingsoon' ? <ComingSoon pageSetter={pageSetter} type='desktop' />
                      : page === 'taxes' ? <TaxesLoad properties={properties}
                        property={property}
                        mobile={mobile}
                        deviceWidth={deviceWidth}
                        accountStatus={accountStatus}
                        hadTrial={hadTrial}
                        yearsArr={yearsArr}
                        handleChangeYear={handleChangeYear}
                        year={selectedYearTaxes}
                        propertiesNoPics={propertiesNoPics}
                        toggleDrawer={toggleDrawer}
                        taxRefresher={taxRefresher}
                        refreshSetter={refreshSetter}
                      />
                        : <PropertiesMain toggleDrawer={toggleDrawer}
                          properties={properties}
                          property={property}
                          updateProperty={updateProperty}
                          mobile={mobile}
                          accountStatus={accountStatus}
                          hadTrial={hadTrial}

                          windowsArr={windowsArr}
                          yearsArr={yearsArr}
                          handleChangeYear={handleChangeYear}
                          selectedYear={selectedYearStats}
                          windowName={selectedWindowName}
                          windowNum={selectedWindowNum}
                          loading={loading}
                          handleChangeCheckbox={handleChangeCheckbox}
                          propertiesNoPics={propertiesNoPics}
                          includeCapEx={includeCapEx}
                          page={page}
                          propertiesRefresher={propertiesRefresher}
                          loadingSetter={loadingSetter}

                          currentYear={currentYear}
                          currentMonth={currentMonth}
                          taxRefresher={taxRefresher}
                          
                          plaidAccount={plaidAccount}
                          transactionsRefresher={transactionsRefresher}
                          refreshSetter={refreshSetter}
                          deviceWidth={deviceWidth}
                        />
                }
                </div>
            </div>
          </div>
          {showPlaidErrAlert ?
            <div className='plaidErrAlert'>
              <p className='headerMetric alertPadding'>Error getting transactions from your bank account. <PlaidReconnectModal publicToken={plaidReconnectLinkToken} /></p>
            </div>
            : <div className='plaidErrAlert newTransAlertHidden'><p className='headerMetric negative alertPadding'>Error getting transactions from your bank account. <PlaidReconnectModal /></p></div>
          }
        </div>
      }
    </>
  );
}

