import React, {useState, useEffect} from 'react';
import { InfoContainer } from '../CoreStructure/InfoContainer/InfoContainer';
import { CalculatorData } from './CalculatorData';
export const CalculatorContainer = (props) => {
    

        return ( 
             <InfoContainer type='calculator' label={'New Purchase Calculator'}  toggleDrawer={props.toggleDrawer} mobileType={props.mobileType} >
                <CalculatorData toggleDrawer={props.toggleDrawer} 
                                mobileType={props.mobileType} />
             </InfoContainer>
        )

  
};