import React from 'react';
import { AreaChart, Area, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer } from 'recharts';
import { 
    getColor
    } from '../../../../../Utilities/functions';
import { tooltipMonthLabel, axisMonthLabel, yAxisFormatter } from '../../../../CoreStructure/Drawer/Shared/Misc';



export const PropertyValueChart = (props) => {

    let currentPropertyValue = props.currentPropertyValue
    let propertyID = props.id;
    let toggleDrawer = props.toggleDrawer;
    
    let monthlyLTVForYear=props.monthlyLTVForYear;
    let equityColor = getColor('--midGreen');
    let debt = getColor('--midRed');
    let valueColor = getColor('--waybozBlue');
    let gridColor = getColor('--lineGray');


    function CustomTooltip({ payload, label, active }) {
       


        if (active && payload.length > 0) {

        

             let propertyValue = payload[0].value;
             let debt = payload[0].value-payload[1].value;
             let equity = payload[1].value;
             let ltv = propertyValue === 0 ? 0 : debt/propertyValue*100;

             let equityClass = 'row positive value equityLegend'
             if (equity < 0) {
                equityClass = 'row negative value equityLegend'
             }

          return (
            <div className="custom-tooltip">
                {tooltipMonthLabel(label)}
    
              <div className='payload'>
                    <div>

                        <div className='flexDisplay'>
                            <p className='row' >{`${payload[0].name}`}:</p>
                            <p className="row value">{`${new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 0
                                }).format((propertyValue.toFixed(0)))}`}</p>
                        </div>
                
                        
                        <div className='flexDisplay'>
                            <p className='row negative' >Your Debt:</p>
                            <p className="row negative value">-{`${new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0
                                        }).format((debt.toFixed(0)))}`}</p>
                        </div>
                        

                        <div className='flexDisplay'>
                            <p className='row positive equityLegend' >{`${payload[1].name}`}:</p>
                            <p className={equityClass}>{`${new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 0
                                }).format((equity.toFixed(0)))}`}</p>
                        </div>

                        <div className='flexDisplay'>
                            <p className='row equityLegend' >Loan to Value:</p>
                            <p className='row value equityLegend'>{`${new Intl.NumberFormat('en-US', {
                                    style: 'decimal',
                                    minimumFractionDigits: 2
                                }).format(ltv.toFixed(2))}%`}</p>
                        </div>

                    </div>

              </div>
             
            </div>
          );
        }
        return null;
      }


      // note, i am not passing in the property ID in some cases so if renderLegend is brought back it will break!!!!
      const renderLegend = (props) => {
        const { payload } = props;
        return (
            <div>

                <div style={{display:'flex', padding:20, justifyContent:'space-around'}}>
                    <div>
                        <p className='row'>EoY Property Value:</p>
                    </div>
                    <div style={{textAlign:'right'}}>
                        <p className='row'>{`${new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                            minimumFractionDigits: 0
                                        }).format((monthlyLTVForYear[monthlyLTVForYear.length-1].value.toFixed(0)))}`}</p>
                        {propertyID === -1 ? '' : <p className='tertiaryLabelLightColorless positive updateValue hover' onClick={() => toggleDrawer({drawerType:'editProperty', id:propertyID})}><em>Click to Update</em></p>}
                        
                    </div>
                </div>

                

            </div>
        );
      }


      let newArray = [];
      // Loop through the original array
      for (let i = 0; i < 12; i++) {
          // Get a copy of the current object
          let currentObject = { ...monthlyLTVForYear[i] };
          
          // Get the value of the 'month' key
          let currentMonth = currentObject.month;
          
          // Pass the month value to the processing function and update the 'month' key with the returned value
          currentObject.month = axisMonthLabel({month:i+1});
          
          // Push the modified object into the new array
          newArray.push(currentObject);
      }
    

    let mobile = props.mobileType === 'medium' || props.mobileType === 'small' || props.mobileType === 'xsmall' ? true : false;


    return (
        <div>
            <ResponsiveContainer width="100%" height={mobile ? 300 : 340}>
            <AreaChart data={newArray} margin={{ top: 5, right: 20, left: 40, bottom: 5 }}>
                
                <CartesianGrid vertical={false} stroke={gridColor} />
                <Area type="monotone" name='Property Value' dataKey="value" stroke={valueColor} fillOpacity={1} fill={debt} unit='$' />
                <Area type="monotone" name='Your Equity' dataKey="equity" stroke={equityColor} fillOpacity={1}  fill={equityColor} unit='$' />
                
                <XAxis  dataKey='month' 
                        angle = '-45'
                        tickMargin='10'
                        interval={mobile ? 1 : 'preserveStartEnd'}
                        fontSize={mobile ? 14 : 16}
                         />
                
                <YAxis  type='number' 
                        tickSize='8'
                        tickCount='5'
                        padding={{ top: 0 }}
                        tickFormatter={yAxisFormatter}
                        fontSize={mobile ? 14 : 16}
                        />
                <Tooltip content={<CustomTooltip />} />
            </AreaChart>
            </ResponsiveContainer>
      
        </div>
    )
};

